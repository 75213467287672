<template>
  <div>
    <Header v-if="iSheader" />
    <Nlh v-else />
    <!-- 顶图 -->
    <div class="about">
      <div class="banner">
        <div class="phone">
          <p>联系电话: <span>400 8234 521</span></p>
          <div>申请会员服务</div>
        </div>
      </div>
      <!-- 管理团队 -->
      <div class="team">
        <!-- <div class="title">管理团队</div>
        <div class="team-list">
          <div class="team1">
            <img class="initavatar" src="../../assets/images/aboutUs/ter2.png" alt="" />
            <p class="init">谢志远 创始人</p>
            <p class="intr">
              老师有长达10年的工作经验，抱有以心换心、精益求精的态度，用专业的婚恋服务经验帮助会员收获最完美的婚姻，让会员切身体会到“为爱情投资’就是“为幸福投资”的真正所在价值和意义。擅长情感分析，婚恋匹配，恋爱指导，婚姻维护，再婚指导，解决情感危机。荣获佛山地区“最佳红娘”、“金牌红娘”等称号
            </p>
          </div>
          <div class="team1">
            <img class="initavatar" src="../../assets/images/aboutUs/ter1.png" alt="" />
            <p class="init">老师 总监</p>
            <p class="intr">
              从事婚恋服务多年，具有资深的婚恋咨询经验。他从婚恋技巧、两性相处关系指导等帮助每个单身会员，牵手成功的会员都表示这样全面的婚恋指导给他们的生活带来了质的改变。愿我的每个会员都可以嫁给爱情，擅长恋爱心理分析、恋爱类型识别、恋爱关系冲突处理，荣获“服务之星”“明星红娘”等称号
            </p>
          </div>
          <div class="team1">
            <img class="initavatar" src="../../assets/images/aboutUs/ter3.png" alt="" />
            <p class="init">老师 总监</p>
            <p class="intr">
              老师用心跟会员沟通，把会员当成自己的朋友、亲人一样，去了解他们的的原生家庭、性格、情感经历，帮助会员去分析自己在感情中的优劣势，用专业的知识去帮到会员提升自己、了解异性思维方式、提高情绪价值、学会与被爱的能力，找到属于自己的那个对的人。荣获“服务之星”、“优秀红娘”等称号
            </p>
          </div>
        </div> -->
      </div>
      <!-- 公司简介 -->
      <div class="brief">
        <div class="firm">
          <p class="company">
            公司简介
            <span></span>
          </p>
          <div class="content">
            广东省也在网信息技术有限公司(以下简称也在网)。也在网是由专业红娘提供服务的严肃婚恋相亲网站，网站采用红娘+网络的高效婚配服务模式，全程精心主动的为会员提供完善的征婚服务。
            也在网创始于2016年，也在网红娘均经过专业的婚恋学，心理学培训，结合八年多的征婚服务经验，给您提供用心细致，专业个性化的红娘服务。<br />
            <br />
            公司名称:广东省也在网信息技术有限公司 成立日期: 2016年3月<br />
            公司地址:佛山市顺德区大良镇国泰南路建南金融中心16楼1604室<br />
            主要业务:婚恋服务，活动策划<br />
            目前项目:从事专业高端婚恋服务，依客户个人需求为其量身定制最为合适的婚恋服务。<br />
            近期项目:联合美的、碧桂园、格兰仕等多家大型企业举办了多次高端联谊活动，配对成功率高达80%。<br />
            公司文化:诚信立足，创新致远;创优质品牌，铸一流形象。<br />
          </div>
        </div>
      </div>
      <!-- 合作伙伴 -->

      <div class="storedetails">
        <div class="work">合作伙伴</div>
        <div class="information">
          <div class="details">
            <div class="image">
              <img src="../../assets/images/aboutUs/kefu.png" alt="" />
            </div>
            <div>免费咨询热线</div>
            <p>4008-234-521</p>
          </div>
          <div class="line">
            <img src="../../assets/images/aboutUs/line.png" alt="" />
          </div>
          <div class="nformation">
            <div class="details">
              <div class="image">
                <img src="../../assets/images/aboutUs/time.png" alt="" />
              </div>
              <div>周一 ~ 周日</div>
              <p>9:30-21:30</p>
            </div>
          </div>
          <div class="line">
            <img src="../../assets/images/aboutUs/line.png" alt="" />
          </div>
          <div class="nformation nformation-L">
            <div class="details">
              <div class="image">
                <img src="../../assets/images/aboutUs/diwei.png" alt="" />
              </div>
              <div class="address">
                佛山市顺德区国泰南路建南金融中心<br />
                佛山市禅城区季华五路万科金融中心
              </div>
            </div>
          </div>
          <div class="line">
            <img src="../../assets/images/aboutUs/line.png" alt="" />
          </div>
          <div class="nformation">
            <div class="details">
              <div class="image">
                <img src="../../assets/images/aboutUs/mail.png" alt="" />
              </div>
              <div>yezaicom@163.com</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/layout/footer";
import Header from "@/components/layout/header";
import Nlh from "@/components/layout/nlh";
import local from "@/api/common/local.js";
export default {
  metaInfo: {
    title: "也在网-婚恋平台致力于您幸福的使命",
    // meta: [
    //   { charset: "utf-8" },
    //   {
    //     name: "viewport",
    //     content:
    //       "width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no",
    //   },
    //   {
    //     name: "keywords",
    //     content: "交友,交友网,征婚交友,网上交友,征婚,征婚网,征婚交友网,交友中心,婚恋交友,同城交友,离异征婚,同城约会吧,约会吧征婚,约会吧交友,北京交友,佛山交友,找对象,婚介,二婚,再婚,也在网,也在"
    //   }, {
    //     name: "description",
    //     content: "也在网创始于2016年，也在网红娘均经过专业的婚恋学，心理学培训，结合多年的征婚服务经验，给您提供用心细致，专业个性化的红娘服务。也在网：成就天下姻缘！"
    //   }
    // ],
  },
  data() {
    return {
      iSheader: true,
    };
  },
  components: {
    Footer,
    Header,
    Nlh,
  },
  created() {
    let Data = local.get("access_token");
    if (Data) {
      this.iSheader = true;
    } else {
      this.iSheader = false;
    }
  },
};
</script>

<style lang="less" scoped>
.about {
  width: 100%;
  padding-top: 80px;
  // height: 1350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 1200px;

  .banner {
    width: 100%;
    height: 466px;
    background: url("../../assets/images/aboutUs/about.png") no-repeat;
    background-size: 100% 100%;
    position: relative;

    .phone {
      // width: 280px;
      // height: 100px;
      width: 370px;
      height: 148px;
      background: #fff;
      border-radius: 6px;
      position: absolute;
      left: 65%;
      top: 65%;
      text-align: center;
      // box-shadow: 0px 2px 0px dimgray;
      // box-shadow: -2px 3px 0px red;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      p {
        font-size: 24px;
        font-weight: bold;
        // color: black;
        color: #333333;
        line-height: 42px;
        margin-top: 6px;

        span {
          font-size: 20px;
          font-weight: 400;
        }
      }

      div {
        width: 320px;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        background: linear-gradient(90deg, #ffa1a7 0%, #fd686e 100%);
        cursor: pointer;
        line-height: 40px;
        margin: 0 auto;
        border-radius: 6px;
        color: #f7f7f7;
      }
    }
  }

  .team {
    width: 100%;
    // padding-bottom: 90px;
    padding-bottom: 10px;
    background: #f7f7f7;
    text-align: center;

    .title {
      margin: 0 auto;
      margin-top: 62px;
      margin-bottom: 70px;
      // width: 96px;
      height: 33px;
      font-size: 24px;
      // font-family: PingFangSC-Medium, PingFang SC;
      font-weight: bold;
      color: #333333;
      line-height: 33px;
    }

    .team-list {
      margin: 0 auto;
      // width: 1000px;
      width: 1090px;
      width: 1200px;
      display: flex;
      justify-content: space-between;

      .team1 {
        width: 320px;
        height: 486px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        background: #fff;

        .initavatar {
          width: 180px;
          height: 180px;
          background: pink;
          margin: 20px 77px 24px 63px;
          border: 1px solid #fff;
          border-radius: 50%;
        }

        .init {
          font-size: 18px;
          color: #333333;
          line-height: 36px;
          margin-bottom: 16px;
        }

        .intr {
          width: 254px;
          height: 198px;
          font-size: 14px;
          color: #555555;
          line-height: 25px;
          text-align: center;
        }
      }
    }
  }

  .brief {
    width: 100%;
    height: 476px;
    background: url("../../assets/images/aboutUs/flow.png") no-repeat;
    background-size: 100% 100%;

    .firm {
      // position: absolute;
      width: 1130px;
      height: 476px;
      margin: 0 auto;

      // z-index: 99;
      .company {
        font-size: 30px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #fff;
        line-height: 42px;
        margin-top: 38px;

        span {
          display: block;
          width: 100px;
          height: 4px;
          background: #fff;
          margin-top: 10px;
          margin-left: 5px;
        }
      }

      .content {
        width: 777px;
        height: 248px;
        width: 777px;
        height: 248px;
        font-size: 16px;
        color: #ffffff;
        // color: rgba(255, 255, 255, 0.9);
        line-height: 28px;
        margin-left: 120px;
        margin-top: 58px;
        text-align: justify;
      }
    }
  }

  .storedetails {
    width: 1200px;
    margin: 0 auto;
    margin-top: 70px;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .information {
      width: 1197px;
      background: url("../../assets/images/aboutUs/zhidao.png") no-repeat;
      height: 374px;
      display: flex;

      .nformation-L {
        width: 300px;

        .details {
          width: 100%;

          .address {
            width: 100%;
          }
        }
      }

      .details {
        width: 222px;
        height: 274px;
        // border-right: 1px solid #fefefe;
        padding-top: 70px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .image {
          width: 70px;
          height: 70px;
          // background: #fff;
          margin: 30px 79px 20px;
        }

        div {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 28px;
        }

        p {
          font-size: 24px;
          font-weight: bold;
          color: #ffffff;
          line-height: 42px;
        }

        .address {
          width: 200px;
          height: 180px;
          text-align: center;
          padding: 10px 20px;
          box-sizing: border-box;
        }
      }

      .line {
        margin-top: 100px;
      }
    }

    .work {
      // width: 96px;
      height: 33px;
      font-size: 24px;
      font-weight: bold;
      color: #333333;
      line-height: 33px;
      margin: 0 auto;
      margin-bottom: 60px;
    }
  }
}
</style>
