<template>
  <div class="login" ref="VueApp" style="display: none">
    <!-- 顶部  固定顶部 -->
    <div class="page" :class="tabshow ? 'page-a' : ''">
      <div  class="top-bar">
        <div class="header-logo">
          <img src="../../assets/images/tubiao/yezai_logo_B.png" alt="logo" />
          <!-- <img src="../../assets/images/tubiao/yezai_logo_A.png" alt="logo" /> -->
        </div>
        <router-link to="/login">
          <div class="BTN-box">登录</div>
        </router-link>
      </div>
    </div>
    <!-- 海报 -->
    <div class="m-banner">
      <img :src="banner" alt="" style="" />
      <div class="b-register-form shake" ref="win">
        <div class="c-register-form-add">
          <div class="b-title">60秒完成注册，幸福一辈子!</div>
          <div class="b-btn">
            <div class="b-btn-sex">
              <span>我的性别: </span>
              <el-radio v-model="radio" label="0" style="margin-left: 10px">男</el-radio>
              <el-radio v-model="radio" label="1">女</el-radio>
            </div>
            <div class="b-btn-date">
              出生年月:
              <Ymdata style="margin-left: 10px" @Ymdata="hui" />
              <p class="csrq" v-show="cc">请填写出生日期</p>
            </div>
            <div class="b-btn-sex b-btn-sex1">
              <span>婚姻状况: </span>
              <el-radio v-model="radio1" label="0" style="margin-left: 10px">未婚</el-radio>
              <el-radio v-model="radio1" label="1">离异</el-radio>
              <el-radio v-model="radio1" label="2">丧偶</el-radio>
            </div>
          </div>

          <div class="btn" @click="register">免费注册</div>

        </div>
      </div>
    </div>
    <div class="bar">
      <div class="bar-banner" style="width: 416px; hight: 398px">
        <div class="box1">
          <img src="../../assets/images/login/xin.png" alt="" />
          <div>
            <p>高端婚恋服务</p>
            <p>致力提供高诚信度、高效率 高品质的婚恋交友服务</p>
          </div>
        </div>
        <div class="box2">
          <img src="../../assets/images/login/xina.png" alt="" />
          <div>
            <p>专业婚恋顾问团队</p>
            <p>婚恋顾问团队至关重要，帮 助客户做出的每一步选择</p>
          </div>
        </div>
        <div class="box3">
          <img src="../../assets/images/login/xinb.png" alt="" />
          <div>
            <p>私人婚恋定制服务</p>
            <p>依客户个人需求为其量身定 制最为合适的婚恋服务</p>
          </div>
        </div>
        <div class="box4">
          <img src="../../assets/images/login/xinc.png" alt="" />
          <div>
            <p>联谊活动规划</p>
            <p>精益求精，经验丰富的团队 策划精品高质的相亲会</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 移动图 -->
    <div class="yezai-get">
      <div class="yezai-q">
        <h3>在也在 可以得到什么？</h3>
      </div>
      <div class="yezai-img">
        <div class="img-left">
          <!-- 001 -->
          <div class="box1 box" id="main">
            <div>
              <div class="box-img">
                <img :src="yd" alt="" />
                <span>{{ ydYear }}·{{ ydHight }}</span>
              </div>
            </div>
          </div>
          <!-- 002 -->
          <div class="box2 box" id="main1">
            <div>
              <div class="box-img">
                <img :src="yd1" alt="" />
                <!-- <span>18岁·165CM</span> -->
                <span>{{ yd1Year }}·{{ yd1Hight }}</span>
              </div>
            </div>
          </div>
          <!-- 003 -->
          <div class="box3 box" id="main2">
            <div>
              <!-- <img src="../../assets/images/sourceMaterial/mz3.jpg" alt="" /> -->
              <div class="box-img">
                <img :src="yd2" alt="" />
                <!-- <span>18岁·165CM</span> -->
                <span>{{ yd2Year }}·{{ yd2Hight }}</span>
              </div>
            </div>
          </div>
          <!-- 004 -->
          <div class="box4 box" id="main3">
            <div>
              <!-- <img src="../../assets/images/sourceMaterial/mz4.jpg" alt="" /> -->
              <div class="box-img">
                <img :src="yd3" alt="" />
                <!-- <span>18岁·165CM</span> -->
                <span>{{ ydYear }}·{{ ydHight }}</span>
              </div>
            </div>
          </div>
          <div class="box5 box" id="main4">
            <div>
              <!-- <img src="../../assets/images/sourceMaterial/mz5.jpg" alt="" /> -->
              <div class="box-img">
                <img :src="yd4" alt="" />
                <!-- <span>18岁·165CM</span> -->
                <span>{{ yd4Year }}·{{ yd4Hight }}</span>
              </div>
            </div>
          </div>
          <div class="box6 box" id="main5">
            <div>
              <!-- <img src="../../assets/images/sourceMaterial/mz6.jpg" alt="" /> -->
              <div class="box-img">
                <img :src="yd5" alt="" />
                <!-- <span>18岁·165CM</span> -->
                <span>{{ yd5Year }}·{{ yd5Hight }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-right">
          <div class="right-title">Members</div>
          <div class="right-hui">海量优质会员</div>
          <div class="right-dan">众多单身用户，众多类型供你选择</div>
          <ul>

            <li :class="index == indexI ? 'active' : ''" @click="index = indexI" v-for="(item, indexI) in listIndex"
              :key="indexI">{{ item }}</li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 指示图 -->
    <div class="banner-ZS">
      <img :src="banner4" alt="" />
    </div>
    <!-- 也在服务 -->
    <div class="v">
      <div class="yezai-service">
        <div class="service-title">
          <p class="service-title-a">Service</p>
          <p class="service-title-b">红娘服务</p>
          <p class="service-title-c">高端 高效 精准 贴心</p>
        </div>
        <ul class="clearfloat" ref="nima">
          <li>
            <div class="head">
              <div class="a"><span>1</span></div>
              <div class="lie">
                <p>当地门店对接服务</p>
                <p>不做加盟，打造一线产品服务</p>
              </div>
            </div>
            <div class="li-img">
              <img :src="hong1" alt="" />
            </div>
          </li>
          <li>
            <div class="head">
              <div class="a"><span>2</span></div>
              <div class="lie">
                <p>资深红娘一对一服务</p>
                <p>资深红娘团队，专业婚恋测评，全程一对一贴心服务</p>
              </div>
            </div>
            <div class="li-img">
              <img :src="hong2" alt="" />
            </div>
          </li>
          <li>
            <div class="head">
              <div class="a"><span>3</span></div>
              <div class="lie">
                <p>安全隐私保护</p>
                <p>专属红娘多重认证资料，为你建立专属秘密征婚档案</p>
              </div>
            </div>
            <div class="li-img">
              <img :src="hong3" alt="" />
            </div>
          </li>
          <li>
            <div class="head">
              <div class="a"><span>4</span></div>
              <div class="lie">
                <p>精选优质会员</p>
                <p>
                  根据你的要求，多重渠道为你甄选高诚意优质会员，提高婚配效率
                </p>
              </div>
            </div>
            <div class="li-img">
              <img :src="hong4" alt="" />
            </div>
          </li>
          <li>
            <div class="head">
              <div class="a"><span>5</span></div>
              <div class="lie">
                <p style="margin-top: 10px">全方位托管式服务</p>
                <p>
                  从“专业配对”、“心灵感情”、“婚恋资讯”三个维度。全程为你
                  <br />
                  提供高品质、全方位的高端婚恋服务。
                </p>
              </div>
            </div>
            <div class="li-img">
              <img :src="hong5" alt="" />
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 海报002 -->
    <div class="m-handsome-boy">
      <img :src="banner5" alt="" />
    </div>
    <!-- 自助服务 -->
    <div class="m-self">
      <div class="m-self-service">
        <div class="self-video">
          <img src="../../assets/images/zu/001.png" ref="move" alt="" />
        </div>
        <div class="video-btn">
          <ul ref="list">
            <li class="ac" @mouseenter="enter(1)" >
              <div class="icon">
                <img src="../../assets/images/login/jz.png" alt="" />
              </div>
              <div class="title">
                <p class="top-title">精准推荐</p>
                <p class="sub-title">更精准适合的推荐</p>
              </div>
            </li>
            <li @mouseenter="enter(2)">
              <div class="icon">
                <img src="../../assets/images/login/x.png" alt="" />
              </div>
              <div class="title">
                <p class="top-title">心有灵析</p>
                <p class="sub-title">剖析内心，找到三观匹配的TA</p>
              </div>
            </li>
            <li @mouseenter="enter(3)">
              <div class="icon">
                <img src="../../assets/images/login/dta.png" alt="" />
              </div>
              <div class="title">
                <p>动态</p>
                <p class="sub-title">走进TA的生活圈</p>
              </div>
            </li>
            <li @mouseenter="enter(4)">
              <div class="icon">
                <img src="../../assets/images/login/spa.png" alt="" />
              </div>
              <div class="title">
                <p>也在短视频</p>
                <p class="sub-title">面对面相亲，真诚高效</p>
              </div>
            </li>


          </ul>
        </div>
        <div class="video-title">
          <p>Service</p>
          <p>自助服务</p>
          <p>自助寻爱，找到合适你的人</p>
        </div>
      </div>
    </div>
    <!-- 海报 海报图003 -->
    <div class="m-self-help">
      <img :src="banner6" alt="" />
    </div>

    <div class="Slogan">
      <div class="yezai-Slogan">
        <div class="yezai-title">
          <img :src="yezai" alt="" />
        </div>
        <p>遇见幸福才是真的好！</p>
        <p>配对成功的故事</p>
      </div>
    </div>
    <div class="Successful-cases">
      <ul>
        <li v-for="item in listData" :key="item.id" @click="cj">
          <div class="love-img">
            <img :src="item.htImg" alt="" />
            <div class="love-review">
              <div class="review-bg"></div>
              <div class="review-btn">
                <span>查看真爱故事</span>
                <span class="goto-icon el-icon-right"></span>
              </div>
            </div>
          </div>
          <h4 class="love-title">{{ item.title }}</h4>
          <p class="love-desc">
            {{ item.manifesto }}
          </p>
        </li>
      </ul>
    </div>
    <!-- 指尖空间01 -->
    <div class="m-get-marryP">
      <div class="marryP-left">
        <img src="../../assets/images/banner/banner-Top-L.png" alt="" />
        <div class="marryP-left-title">
          <h4>爱情意味着两个人就是世界</h4>
          <p>下载也在网客户端</p>
          <div class="qr-code">
            <img src="../../assets/images/logo/logo.png" alt="" />
          </div>
          <span>下载APP，体验更多功能</span>
        </div>
      </div>
      <div class="marryP-right">
        <img src="../../assets/images/banner/banner-Top-R.png" alt="" />
        <div class="marryP-right-title">
          <h4>听闻你也在这里</h4>
          <p>注册也在网</p>
          <a href="/register" class="goto-register">
            <span>点击找到另一半</span>
            <span class="goto-icon" :style="{ background: 'url(' + arrowB + ')  no-repeat' }"></span>
          </a>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Ymdata from "@/components/Ymdata";
import Footer from "@/components/layout/footer.vue";
import { storyList } from "@/api/member/member.js";
import local from "@/api/common/local.js";
import { infocur } from "@/api/login/login.js"; //loginAuth
import { ossL } from "@/api/common/oss/ossL.js";
export default {
  metaInfo: {
    title: "也在网-征婚、交友、相亲，严肃单身婚恋交友平台",
    // meta: [
    //   { charset: "utf-8" },
    //   {
    //     name: "viewport",
    //     content:
    //       "width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no",
    //   },
    //   {
    //     name: "keywords",
    //     content: "交友,交友网,征婚交友,网上交友,征婚,征婚网,征婚交友网,交友中心,婚恋交友,同城交友,离异征婚,同城约会吧,约会吧征婚,约会吧交友,北京交友,佛山交友,找对象,婚介,二婚,再婚,也在网,也在"
    //   }, {
    //     name: "description",
    //     content: "也在网创始于2016年，也在网红娘均经过专业的婚恋学，心理学培训，结合多年的征婚服务经验，给您提供用心细致，专业个性化的红娘服务。也在网：成就天下姻缘！"
    //   }
    // ],
  },
  data() {
    return {
      scrollTopNum: "", //页面滚动的高度
      tabshow: false, //是否进行某种操作
      Num: "",

      radio: "0",
      radio1: "0",
      csrq: "",
      cc: false,

      video: [
        "../../assets/images/zu/001.png",
        "../../assets/images/zu/002.png",
        "../../assets/images/zu/003.png",
        "../../assets/images/zu/004.png",
      ],//自助服务相片

      // banner: require("@/assets/images/banner/banner_b.png"),
      banner: require("@/assets/images/banner/banner_z.jpg"),
      banner3: require("@/assets/images/banner/banner-1.png"),
      banner4: require("@/assets/images/banner/renwu.png"),
      banner5: require("@/assets/images/banner/banner-12.png"),
      banner6: require("@/assets/images/banner/banner-4.png"),

      hong1: require("@/assets/images/banner/hong-6.png"),
      hong2: require("@/assets/images/banner/hong-7.png"),
      hong3: require("@/assets/images/banner/hong-9.png"),
      hong4: require("@/assets/images/banner/hong-10.png"),
      hong5: require("@/assets/images/banner/hong-8.png"),

      yezai: require("@/assets/images/banner/yezai001.png"),

      listData: [],

      // marryLeft: require("@/assets/images/banner/banner-left.png"),
      // marryRight: require("@/assets/images/banner/banner-right.png"),

      arrowB: require("@/assets/images/tubiao/arrow.png"),

      index: 0,
      //移动图
      yd: require("@/assets/images/sourceMaterial/yezaiWoman/01-24-165.jpg"),
      ydYear: "24岁",
      ydHight: "165cm",
      yd1: require("@/assets/images/sourceMaterial/yezaiWoman/02-28-160.jpg"),
      yd1Year: "28岁",
      yd1Hight: "160cm",
      yd2: require("@/assets/images/sourceMaterial/yezaiWoman/03-28-160.jpg"),
      yd2Year: "28岁",
      yd2Hight: "160cm",
      yd3: require("@/assets/images/sourceMaterial/yezaiWoman/04-30-168.png"),
      yd3Year: "30岁",
      yd3Hight: "168cm",
      yd4: require("@/assets/images/sourceMaterial/yezaiWoman/05-26-166.png"),
      yd4Year: "26岁",
      yd4Hight: "166cm",
      yd5: require("@/assets/images/sourceMaterial/yezaiWoman/06-27-157.jpg"),
      yd5Year: "27岁",
      yd5Hight: "157cm",
      listIndex: [
        '柔美',
        '清纯',
        '优雅',
        '型男',
        '成熟',
        '事业有成'
      ]
    };
  },
  components: {
    Ymdata,
    Footer,
  },
  created() {
    this.storyList();
    // this.$router.push("/home");
    //  if (local.get("access_token")) {
    //   this.$router.push("/n/myyezai");
    // } else {
    //   this.$router.push("/");
    // }
  },
  mounted() {
    this.$refs.VueApp.style.display = "block";
    window.addEventListener("scroll", this.handleScroll, true);
    this.li();
    this.qiuqiu();
    this.qiuqiu1();
    this.qiuqiu2();
    this.qiuqiu3();
    this.qiuqiu4();
    this.qiuqiu5();
    this.tz();
  },
  methods: {
    async tz() {
      let Data = local.get("access_token");
      if (Data) {

        const { code } = await infocur(Data);
        if (code == 0) {
          this.$router.push({
            path: "/n/myyezai",
          });
        }
      }
    },
    cj() {
      this.$router.push({
        path: "/register",
      });
    },
    //会员故事
    async storyList() {
      const { code, data } = await storyList();
      if (code == 0) {
        // console.log("数据", data);
        // this.listData = data;
        data.map(async (item) => {
          this.listData.push(
            Object.assign({}, item, {
              show: true,
              htImg: ossL(item.mainImg),
            })
          );
        });

        // console.log("数据", this.listData);
      }
    },
    Commonf(main1, circles1) {
      // var main = main1; //获取运动边界和10个div
      var circles = circles1;
      var container = []; //存放10个球的每个具体信息，包括坐标，速度等值
      var arr = [];
      var maxW = 20; //初始化运动的最大宽和高，初始定义0
      var maxH = 20;
      //数组对象的初始化
      for (var i = 0; i < circles.length; i++) {
        arr = [];
        arr.x = Math.floor(Math.random() * (maxW + 0.1)); //初始x坐标
        // console.log("00000", Math.random());
        // arr.x = Math.random() + (maxW + 0.1); //初始x坐标
        // console.log("小数", arr.x);
        arr.y = Math.floor(Math.random() * (maxH + 0.1)); //初始y坐标
        // arr.y =Math.random() + (maxH + 0.1) //初始y坐标
        arr.movex = Math.floor(Math.random() * 2); //x轴移动方向
        // arr.movex = Math.random() * 2; //x轴移动方向
        arr.movey = Math.floor(Math.random() * 2); //y轴移动方向
        // arr.movey = Math.random() * 2; //y轴移动方向
        arr.speed = 0.5;
        arr.timer = null; //计时器
        arr.index = i; //索引值
        container.push(arr); //存放所有的属性值
        // circles[i].style.left = arr.x + "px"; //小球位置初始化
        circles[i].style.transform = `translateX(${arr.x}px)`; //小球位置初始化
        circles[i].style.transform = `translateY(${arr.y}px)`;
      }
      //移动函数
      function move(balls) {
        //每个球单独有定时器
        balls.timer = setInterval(function () {
          if (balls.movex === 1) {
            //如果往右跑，则一直加速度，碰到边界，改为反方向运动
            balls.x += balls.speed;
            if (balls.x + balls.speed >= maxW) {
              //防止小球出界
              balls.x = maxW;
              balls.movex = 0; //小球运动方向发生改变
            }
          } else {
            balls.x -= balls.speed; // 1和0表示正反方向
            if (balls.x - balls.speed <= 0) {
              balls.x = 0;
              balls.movex = 1;
            }
          }
          if (balls.movey === 1) {
            balls.y += balls.speed;
            if (balls.y + balls.speed >= maxH) {
              balls.y = maxH;
              balls.movey = 0;
            }
          } else {
            balls.y -= balls.speed;
            if (balls.y - balls.speed <= 0) {
              balls.y = 0;
              balls.movey = 1;
            }
          }
          balls.cx = balls.x + circles[0].offsetWidth / 2; //小球圆心等于：运动中x的值加上自身的半径
          balls.cy = balls.y + circles[0].offsetHeight / 2;
          circles[
            balls.index
          ].style.transform = `translate(${balls.x}px,${balls.y}px)`; //小球相对于屏幕的位置
        }, 50);
      }
      //对每一个小球绑定计时器，让小球动起来
      for (let i = 0; i < container.length; i++) {
        move(container[i]);
      }
    },
    qiuqiu() {
      var main = document.getElementById("main"); //获取运动边界和10个div
      // var circles = main.getElementsByTagName("img");//原001
      var circles = main.getElementsByClassName("box-img");
      this.Commonf(main, circles);
    },
    qiuqiu1() {
      var main = document.getElementById("main1"); //获取运动边界和10个div
      // var circles = main.getElementsByTagName("img");
      var circles = main.getElementsByClassName("box-img");
      this.Commonf(main, circles);
    },
    qiuqiu2() {
      var main = document.getElementById("main2"); //获取运动边界和10个div
      // var circles = main.getElementsByTagName("img");
      var circles = main.getElementsByClassName("box-img");
      this.Commonf(main, circles);
    },
    qiuqiu3() {
      var main = document.getElementById("main3"); //获取运动边界和10个div
      var circles = main.getElementsByClassName("box-img");
      // var circles = main.getElementsByTagName("img");
      this.Commonf(main, circles);
    },
    qiuqiu4() {
      var main = document.getElementById("main4"); //获取运动边界和10个div
      var circles = main.getElementsByClassName("box-img");
      // var circles = main.getElementsByTagName("img");
      this.Commonf(main, circles);
    },
    qiuqiu5() {
      var main = document.getElementById("main5"); //获取运动边界和10个div
      var circles = main.getElementsByClassName("box-img");
      // var circles = main.getElementsByTagName("img");
      this.Commonf(main, circles);
    },
    //选项000000视频
    enter(num) {
      if (num == 1) {
        // this.$refs.move.src = this.video[0];../../assets/images/zu/001.png
        this.$refs.move.src = require("@/assets/images/zu/001.png");
        this.$refs.list.getElementsByTagName("li").forEach((item) => {
          item.style.height = 60 + "px";
          item.children[1].lastElementChild.style.opacity = 0;
          item.children[1].firstElementChild.style.color = "#CCCCCCFF";
        });
        this.$refs.list.getElementsByTagName("li")[0].style.height = 94 + "px";
        this.$refs.list.getElementsByTagName(
          "li"
        )[0].children[1].lastElementChild.style.opacity = 1;
        this.$refs.list.getElementsByTagName(
          "li"
        )[0].children[1].firstElementChild.style.color = "#333333FF";
        this.$refs.list.getElementsByTagName(
          "li"
        )[1].children[0].children[0].src = require("../../assets/images/login/x.png");
        this.$refs.list.getElementsByTagName(
          "li"
        )[2].children[0].children[0].src = require("../../assets/images/login/dta.png");
        this.$refs.list.getElementsByTagName(
          "li"
        )[3].children[0].children[0].src = require("../../assets/images/login/spa.png");
        this.$refs.list.getElementsByTagName(
          "li"
        )[0].children[0].children[0].src = require("../../assets/images/login/jz.png");
      } else if (num == 2) {
        // this.$refs.move.src = this.video[1];
        this.$refs.move.src = require("@/assets/images/zu/002.png");
        this.$refs.list.getElementsByTagName("li").forEach((item) => {
          item.style.height = 60 + "px";
          item.children[1].lastElementChild.style.opacity = 0;
          item.children[1].firstElementChild.style.color = "#CCCCCCFF";
        });

        this.$refs.list.getElementsByTagName("li")[1].style.height = 94 + "px";
        this.$refs.list.getElementsByTagName(
          "li"
        )[1].children[1].lastElementChild.style.opacity = 1;
        this.$refs.list.getElementsByTagName(
          "li"
        )[1].children[1].firstElementChild.style.color = "#333333FF";
        this.$refs.list.getElementsByTagName(
          "li"
        )[0].children[0].children[0].src = require("../../assets/images/login/jza.png");
        this.$refs.list.getElementsByTagName(
          "li"
        )[2].children[0].children[0].src = require("../../assets/images/login/dta.png");
        this.$refs.list.getElementsByTagName(
          "li"
        )[3].children[0].children[0].src = require("../../assets/images/login/spa.png");
        this.$refs.list.getElementsByTagName(
          "li"
        )[1].children[0].children[0].src = require("../../assets/images/login/xa.png");
      } else if (num == 3) {
        // this.$refs.move.src = this.video[2];
        this.$refs.move.src = require("@/assets/images/zu/003.png");
        this.$refs.list.getElementsByTagName("li").forEach((item) => {
          item.style.height = 60 + "px";
          item.children[1].lastElementChild.style.opacity = 0;
          item.children[1].firstElementChild.style.color = "#CCCCCCFF";
        });

        this.$refs.list.getElementsByTagName("li")[2].style.height = 94 + "px";
        this.$refs.list.getElementsByTagName(
          "li"
        )[2].children[1].firstElementChild.style.color = "#333333FF";
        this.$refs.list.getElementsByTagName(
          "li"
        )[0].children[0].children[0].src = require("../../assets/images/login/jza.png");

        this.$refs.list.getElementsByTagName(
          "li"
        )[1].children[0].children[0].src = require("../../assets/images/login/x.png");

        this.$refs.list.getElementsByTagName(
          "li"
        )[3].children[0].children[0].src = require("../../assets/images/login/spa.png");

        this.$refs.list.getElementsByTagName(
          "li"
        )[2].children[0].children[0].src = require("../../assets/images/login/dt.png");

        this.$refs.list.getElementsByTagName(
          "li"
        )[2].children[1].lastElementChild.style.opacity = 1;
      } else if (num == 4) {
        // this.$refs.move.src = this.video[3];
        this.$refs.move.src = require("@/assets/images/zu/004.png");
        this.$refs.list.getElementsByTagName("li").forEach((item) => {
          item.style.height = 60 + "px";
          item.children[1].lastElementChild.style.opacity = 0;
          item.children[1].firstElementChild.style.color = "#CCCCCCFF";
        });

        this.$refs.list.getElementsByTagName("li")[3].style.height = 94 + "px";

        this.$refs.list.getElementsByTagName(
          "li"
        )[3].children[1].firstElementChild.style.color = "#333333FF";

        this.$refs.list.getElementsByTagName(
          "li"
        )[0].children[0].children[0].src = require("../../assets/images/login/jza.png");

        this.$refs.list.getElementsByTagName(
          "li"
        )[1].children[0].children[0].src = require("../../assets/images/login/x.png");

        this.$refs.list.getElementsByTagName(
          "li"
        )[2].children[0].children[0].src = require("../../assets/images/login/dta.png");

        this.$refs.list.getElementsByTagName(
          "li"
        )[3].children[0].children[0].src = require("../../assets/images/login/sp.png");

        this.$refs.list.getElementsByTagName(
          "li"
        )[3].children[1].lastElementChild.style.opacity = 1;
      }
    },
    hui(data) {
      this.csrq = data;
    },

    register() {
      if (this.csrq == "") {
        this.cc = true;
        this.$refs.win.classList.add("shakehover");
        this.gun();
      } else {
        this.$router.push({
          name: "register",
          params: {
            id: 1,
            sex: this.radio,
            csrq: this.csrq,
            Marital: this.radio1,
          },
        });
      }
    },
    //获取当前滚动条距离
    handleScroll() {
      let top =
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        window.pageYOffset;
      this.scrollTopNum = top;
      this.Num = top;
    },

    gun() {
      setTimeout(() => {
        this.$refs.win.classList.remove("shakehover");
      }, 1000);
    },
    li() {
      this.$refs.list.getElementsByTagName(
        "li"
      )[0].children[1].lastElementChild.style.opacity = 1;
    },
  },
  computed: {
    name() {
      return this.index;
    },
  },
  watch: {
    scrollTopNum: function () {
      if (this.scrollTopNum > 45) {
        this.tabshow = true;
      } else {
        this.tabshow = false;
      }
    },
    Num: function () {
      //2600
      if (this.Num >= 2100) {
        this.$refs.nima
          .getElementsByTagName("li")[0]
          .classList.add("service-item-show");
        this.$refs.nima
          .getElementsByTagName("li")[0]
          .classList.remove("service-item-hide");
        this.$refs.nima
          .getElementsByTagName("li")[1]
          .classList.add("service-item-show");
        this.$refs.nima
          .getElementsByTagName("li")[1]
          .classList.remove("service-item-hide");
      } else {
        this.$refs.nima
          .getElementsByTagName("li")[0]
          .classList.add("service-item-hide");
        this.$refs.nima
          .getElementsByTagName("li")[0]
          .classList.remove("service-item-show");
        this.$refs.nima
          .getElementsByTagName("li")[1]
          .classList.add("service-item-hide");
        this.$refs.nima
          .getElementsByTagName("li")[1]
          .classList.remove("service-item-show");
      }
      //2900
      if (this.Num >= 2400) {
        this.$refs.nima
          .getElementsByTagName("li")[2]
          .classList.add("service-item-show");
        this.$refs.nima
          .getElementsByTagName("li")[2]
          .classList.remove("service-item-hide");
        this.$refs.nima
          .getElementsByTagName("li")[3]
          .classList.add("service-item-show");
        this.$refs.nima
          .getElementsByTagName("li")[3]
          .classList.remove("service-item-hide");
      } else {
        this.$refs.nima
          .getElementsByTagName("li")[2]
          .classList.add("service-item-hide");
        this.$refs.nima
          .getElementsByTagName("li")[2]
          .classList.remove("service-item-show");
        this.$refs.nima
          .getElementsByTagName("li")[3]
          .classList.add("service-item-hide");
        this.$refs.nima
          .getElementsByTagName("li")[3]
          .classList.remove("service-item-show");
      }
      //3300
      if (this.Num >= 2900) {
        this.$refs.nima
          .getElementsByTagName("li")[4]
          .classList.add("service-item-show");
        this.$refs.nima
          .getElementsByTagName("li")[4]
          .classList.remove("service-item-hide");
      } else {
        this.$refs.nima
          .getElementsByTagName("li")[4]
          .classList.add("service-item-hide");
        this.$refs.nima
          .getElementsByTagName("li")[4]
          .classList.remove("service-item-show");
      }
    },
    index(newValue) {
      if (newValue == 0) {
        this.yd = require("@/assets/images/sourceMaterial/yezaiWoman/01-24-165.jpg");
        this.ydYear = "24岁";
        this.ydHight = "165cm";
        this.yd1 = require("@/assets/images/sourceMaterial/yezaiWoman/02-28-160.jpg");
        this.yd1Year = "28岁";
        this.yd1Hight = "160cm";
        this.yd2 = require("@/assets/images/sourceMaterial/yezaiWoman/03-28-160.jpg");
        this.yd2Year = "28岁";
        this.yd2Hight = "160cm";
        this.yd3 = require("@/assets/images/sourceMaterial/yezaiWoman/04-30-168.png");
        this.yd3Year = "30岁";
        this.yd3Hight = "168cm";
        this.yd4 = require("@/assets/images/sourceMaterial/yezaiWoman/05-26-166.png");
        this.yd4Year = "26岁";
        this.yd4Hight = "166cm";
        this.yd5 = require("@/assets/images/sourceMaterial/yezaiWoman/06-27-157.jpg");
        this.yd5Year = "27岁";
        this.yd5Hight = "157cm";
      } else if (newValue == 1) {
        this.yd = require("@/assets/images/sourceMaterial/yezaiWoman/07-27-160.jpg");
        this.ydYear = "27岁";
        this.ydHight = "160cm";
        this.yd1 = require("@/assets/images/sourceMaterial/yezaiWoman/08-24-158.jpg");
        this.yd1Year = "24岁";
        this.yd1Hight = "158cm";
        this.yd2 = require("@/assets/images/sourceMaterial/yezaiWoman/09-29-162.png");
        this.yd2Year = "29岁";
        this.yd2Hight = "162cm";
        this.yd3 = require("@/assets/images/sourceMaterial/yezaiWoman/10-27-168.jpg");
        this.yd3Year = "27岁";
        this.yd3Hight = "168cm";
        this.yd4 = require("@/assets/images/sourceMaterial/yezaiWoman/11-28-170.jpg");
        this.yd4Year = "28岁";
        this.yd4Hight = "170cm";
        this.yd5 = require("@/assets/images/sourceMaterial/yezaiWoman/12-30-166.jpg");
        this.yd5Year = "30岁";
        this.yd5Hight = "166cm";
      } else if (newValue == 2) {
        this.yd = require("@/assets/images/sourceMaterial/yezaiWoman/13-28-164.jpg");
        this.ydYear = "28岁";
        this.ydHight = "164cm";
        this.yd1 = require("@/assets/images/sourceMaterial/yezaiWoman/14-25-165.png");
        this.yd1Year = "25岁";
        this.yd1Hight = "165cm";
        this.yd2 = require("@/assets/images/sourceMaterial/yezaiWoman/15-27-166.png");
        this.yd2Year = "27岁";
        this.yd2Hight = "166cm";
        this.yd3 = require("@/assets/images/sourceMaterial/yezaiWoman/16-30-160.png");
        this.yd3Year = "30岁";
        this.yd3Hight = "160cm";
        this.yd4 = require("@/assets/images/sourceMaterial/yezaiWoman/17-29-168.png");
        this.yd4Year = "29岁";
        this.yd4Hight = "168cm";
        this.yd5 = require("@/assets/images/sourceMaterial/yezaiWoman/18-27-170.jpg");
        this.yd5Year = "27岁";
        this.yd5Hight = "170cm";
      } else if (newValue == 3) {
        this.yd = require("@/assets/images/sourceMaterial/yezaiMan/01-26-165.jpg");
        this.ydYear = "26岁";
        this.ydHight = "165cm";
        this.yd1 = require("@/assets/images/sourceMaterial/yezaiMan/02-24-175.png");
        this.yd1Year = "24岁";
        this.yd1Hight = "175cm";
        this.yd2 = require("@/assets/images/sourceMaterial/yezaiMan/03-28-180.png");
        this.yd2Year = "28岁";
        this.yd2Hight = "180cm";
        this.yd3 = require("@/assets/images/sourceMaterial/yezaiMan/04-27-176.png");
        this.yd3Year = "27岁";
        this.yd3Hight = "176cm";
        this.yd4 = require("@/assets/images/sourceMaterial/yezaiMan/05-28-168.jpg");
        this.yd4Year = "28岁";
        this.yd4Hight = "168cm";
        this.yd5 = require("@/assets/images/sourceMaterial/yezaiMan/06-29-176.jpg");
        this.yd5Year = "29岁";
        this.yd5Hight = "176cm";
      } else if (newValue == 4) {
        this.yd = require("@/assets/images/sourceMaterial/yezaiMan/07-26-172.jpg");
        this.ydYear = "26岁";
        this.ydHight = "172cm";
        this.yd1 = require("@/assets/images/sourceMaterial/yezaiMan/08-30-170.jpg");
        this.yd1Year = "30岁";
        this.yd1Hight = "170cm";
        this.yd2 = require("@/assets/images/sourceMaterial/yezaiMan/09-26-170.jpg");
        this.yd2Year = "26岁";
        this.yd2Hight = "170cm";
        this.yd3 = require("@/assets/images/sourceMaterial/yezaiMan/10-30-173.png");
        this.yd3Year = "30岁";
        this.yd3Hight = "173cm";
        this.yd4 = require("@/assets/images/sourceMaterial/yezaiMan/11-23-183.jpg");
        this.yd4Year = "23岁";
        this.yd4Hight = "183cm";
        this.yd5 = require("@/assets/images/sourceMaterial/yezaiMan/12-29-182.jpg");
        this.yd5Year = "29岁";
        this.yd5Hight = "182cm";
      } else if (newValue == 5) {
        this.yd = require("@/assets/images/sourceMaterial/yezaiMan/13-30-183.jpg");
        this.ydYear = "30岁";
        this.ydHight = "183cm";
        this.yd1 = require("@/assets/images/sourceMaterial/yezaiMan/14-26-182.png");
        this.yd1Year = "26岁";
        this.yd1Hight = "182cm";
        this.yd2 = require("@/assets/images/sourceMaterial/yezaiMan/15-28-173.jpg");
        this.yd2Year = "28岁";
        this.yd2Hight = "173cm";
        this.yd3 = require("@/assets/images/sourceMaterial/yezaiMan/16-20-179.jpg");
        this.yd3Year = "20岁";
        this.yd3Hight = "179cm";
        this.yd4 = require("@/assets/images/sourceMaterial/yezaiMan/17-27-183.jpg");
        this.yd4Year = "27岁";
        this.yd4Hight = "183cm";
        this.yd5 = require("@/assets/images/sourceMaterial/yezaiMan/18-28-177.jpg");
        this.yd5Year = "28岁";
        this.yd5Hight = "177cm";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.clearfloat {
  clear: both;
}

@-webkit-keyframes shake {
  0% {
    -webkit-transform: translate(2px, 2px);
  }

  25% {
    -webkit-transform: translate(-2px, -2px);
  }

  50% {
    -webkit-transform: translate(0px, 0px);
  }

  75% {
    -webkit-transform: translate(2px, -2px);
  }

  100% {
    -webkit-transform: translate(-2px, 2px);
  }
}

@keyframes shake {
  0% {
    transform: translate(2px, 2px);
  }

  25% {
    transform: translate(-2px, -2px);
  }

  50% {
    transform: translate(0px, 0px);
  }

  75% {
    transform: translate(2px, -2px);
  }

  100% {
    transform: translate(-2px, 2px);
  }
}

.shakehover {
  -webkit-animation: shake 0.2s infinite;
  animation: shake 0.2s infinite;
}

.b-btn /deep/ .year .name {
  color: #000;
}

.b-btn /deep/ .month-tips,
.b-btn /deep/ .day-tips {
  color: #000;
  text-align: center;
}

.b-btn /deep/ .b-field-input .value {
  background: rgba(216, 216, 216, 0.32);
  border-radius: 4px;
  width: 78px;
  height: 39px;
  line-height: 39px;
  color: #ffffff;
  font-size: 16px;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(100px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(100px);
  }
}

.login {
  width: 100%;
  // height: 100%;
  overflow: hidden;
  min-width: 1200px;

  //顶部
  .page {
    width: 100%;
    position: fixed;
    // background-color: #fd686eff;
    display: flex;
    justify-content: center;
    margin-bottom: 80px;
    top: 0px;
    left: 0px;
    z-index: 9999;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));

    .header {
      min-width: 1200px;
      height: 80px;
      background-color: #fd686eff;
      z-index: 9999;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .header-logo {
        width: 136px;
      }

      .header-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90px;
        height: 42px;
        border-radius: 6px;
        border: 1px solid #ffffff;
        font-size: 18px;
        font-weight: 400;
        line-height: 25px;
        background-color: #fb5057;
        border-radius: 6px;
        cursor: pointer;

        a {
          color: #ffffff;
          font-size: 14px;
        }
      }
    }

    .top-bar {
      min-width: 1200px;
      height: 80px;
      z-index: 9999;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .BTN-box {
        border: 1px solid rgb(255, 255, 255);
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        height: 40px;
        line-height: 40px;
        white-space: nowrap;
        padding: 0 20px;
        text-align: center;
        cursor: pointer;
      }
    }
  }

  .page-a {
    background: linear-gradient(314deg, rgb(250, 129, 133) 0%, #fd686e 100%);
  }

  //海报图 (文字)
  .m-banner {
    width: 100%;
    height: 680px;
    // height: 770px;
    // overflow: hidden;
    /* padding-bottom: (600px / 1920px) * 100%; */
    position: relative;

    background-position: center center;

    img {
      width: 100%;
      // height: 100%;
      // height: 770px;
      height: 680px;

      @media screen {
        min-width: 1920px;
      }

      position: absolute;
      animation: bgBrans linear 1.2s 0.3s;
      animation-fill-mode: forwards;
    }

    .b-register-form {
      border-radius: 12px;
      position: absolute;
      width: 400px;
      height: 380px;
      // top: 135px;
      top: 200px;
      color: #ffffff;
      background-color: rgba(0, 0, 0, 0.6);
      // opacity: 0.6;
      padding: 0 20px;
      right: 18.75%;

      .b-title {
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        border-bottom: 1px solid rgba(255, 255, 255, 0.6);
        height: 84px;
        text-align: center;
        font-size: 22px;
        font-weight: 500;
        color: #ffffff;
        line-height: 30px;
        letter-spacing: 1px;
      }

      .b-btn {
        height: 230px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        font-size: 18px;
        margin-top: -10px;

        .b-btn-sex1 {
          margin-top: -60px !important;
        }

        .b-btn-sex {
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
          line-height: 22px;
          margin-top: -4px;
          margin-left: 10px;

          /deep/ .el-radio__input.is-checked .el-radio__inner {
            border-color: #fa5858;
            background: #fa5858;
          }

          /deep/ .el-radio__input.is-checked+.el-radio__label {
            color: #ffffff;
          }

          /deep/ .el-radio {
            color: #ffffff;
            cursor: pointer;
            margin-right: 30px;
          }

          /deep/ .el-radio__label {
            font-size: 16px;
          }

          /deep/ .el-radio__inner::after {
            width: 7px;
            height: 7px;
          }
        }

        .b-btn-date {
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
          line-height: 22px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          margin-left: -5px;
          margin-top: -55px;

          .csrq {
            position: absolute;
            top: 50px;
            left: 94px;
            color: red;
            font-size: 14px;
          }

          /deep/ .val-e {
            color: #fff;
          }
        }
      }

      .btn {
        width: 380px;
        height: 50px;
        font-size: 18px;
        cursor: pointer;
        font-weight: 400;
        margin-top: -6px;
        background-color: #fd686e;
        border-radius: 12px;
        line-height: 50px;
        text-align: center;
        margin-left: 9px;
        color: #ffffff;
      }
    }
  }

  //文字
  .bar {
    // height: 559px;
    height: 520px;
    display: flex;
    justify-content: center;
    align-items: center;

    .bar-banner {
      width: 416px;
      height: 398px;
      background-color: #000;
      position: relative;
      background: url("../../assets/images/login/pintai.png") no-repeat;
      background-position: center;

      .box1,
      .box2,
      .box3,
      .box4 {
        width: 240px;
        height: 94px;
        position: absolute;
        display: flex;
        align-items: center;

        p:nth-child(1) {
          // width: 108px;
          height: 25px;
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 25px;
        }

        p:nth-child(2) {
          width: 144px;
          height: 46px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #adadad;
          line-height: 23px;
        }
      }

      .box2 {
        right: -384px;
        top: 43px;
      }

      .box1 {
        left: -384px;
        top: 43px;
      }

      .box3 {
        left: -384px;
        top: 260px;
      }

      .box4 {
        right: -384px;
        top: 260px;
        // p:nth-child(1) {
        //   text-align: right;
        // }
      }
    }
  }

  //图
  .banner {
    width: 100%;
    height: 500px;
    overflow: hidden;
    position: relative;
    background-position: center center;

    img {
      width: 100%;
      height: 100%;

      @media screen {
        min-width: 1920px;
      }
    }
  }

  //移动图
  .yezai-get {
    display: flex;
    flex-direction: column;
    align-items: center;

    .yezai-q {
      width: 100%;
      // height: 215px;
      margin-bottom: 100px;
      display: flex;
      justify-content: center;
      align-items: center;

      h3 {
        font-size: 50px;
        font-weight: bold;
      }
    }

    .yezai-img {
      display: flex;
      justify-content: space-between;
      width: 1200px;

      .img-left {
        width: 800px;
        height: 711px;

        position: relative;

        .box {
          display: flex;
          justify-content: center;
          align-items: center;
          // div {
          //   // transform :  translate(10px,20px);
          //   img {
          //     // position: absolute;
          //   }
          // }
        }

        //兔兔兔兔111111111111111111111111111111111111111111111111111111
        .box1 {
          width: 250px;
          height: 250px;
          // background-color: yellow;
          position: absolute;
          left: 40px;

          div {
            .box-img {
              // width: 230px;
              // height: 230px;
              width: 170px;
              height: 170px;
              position: relative;
              cursor: pointer;
              overflow: hidden;

              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
              }

              span {
                display: none;
              }
            }

            .box-img:hover span {
              // display: block;
              position: absolute;
              bottom: 0px;
              left: 0px;
              // width: 230px;
              // height: 230px;
              width: 170px;
              height: 170px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: rgba(255, 159, 163, 0.57);
              color: #fff;
              font-size: 18px;
            }
          }
        }

        .box2 {
          width: 300px;
          height: 300px;
          position: absolute;
          left: 268px;
          top: 200px;

          div {
            .box-img {
              // width: 280px;
              // height: 280px;
              width: 180px;
              height: 180px;
              position: relative;
              cursor: pointer;

              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
              }

              span {
                display: none;
              }
            }

            .box-img:hover span {
              position: absolute;
              bottom: 0px;
              left: 0px;
              // width: 280px;
              // height: 280px;
              width: 180px;
              height: 180px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: rgba(255, 159, 163, 0.57);
              color: #fff;
              font-size: 18px;
            }
          }
        }

        .box3 {
          width: 180px;
          height: 180px;
          // background-color: black;
          position: absolute;
          right: 100px;

          div {
            .box-img {
              // width: 180px;
              // height: 180px;
              width: 160px;
              height: 160px;
              position: relative;
              cursor: pointer;

              // overflow: hidden;
              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
              }

              span {
                display: none;
              }
            }

            .box-img:hover span {
              // display: block;
              position: absolute;
              // top: 160px;
              bottom: 0px;
              left: 0px;
              // left: 20%;
              // width: 180px;
              // // height: 50px;
              // height: 180px;
              width: 160px;
              height: 160px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              // background-color: RGBA(156, 142, 240, 0.57);
              background-color: rgba(255, 159, 163, 0.57);

              // transition: transform linear 0.3s;
              color: #fff;
              font-size: 18px;
              // text-align: center;
            }
          }
        }

        .box4 {
          width: 180px;
          height: 180px;
          // background-color: gold;
          position: absolute;
          right: 15px;
          top: 194px;

          div {
            .box-img {
              // width: 180px;
              // height: 180px;
              width: 150px;
              height: 150px;
              position: relative;
              cursor: pointer;

              // overflow: hidden;
              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
              }

              span {
                display: none;
              }
            }

            .box-img:hover span {
              // display: block;
              position: absolute;
              // top: 160px;
              bottom: 0px;
              left: 0px;
              // left: 20%;
              // width: 180px;
              // height: 180px;
              width: 150px;
              height: 150px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              // background-color: RGBA(156, 142, 240, 0.57);
              background-color: rgba(255, 159, 163, 0.57);

              // transition: transform linear 0.3s;
              color: #fff;
              font-size: 18px;
              // text-align: center;
            }
          }
        }

        .box5 {
          width: 190px;
          height: 190px;
          // background-color: green;
          position: absolute;
          top: 390px;
          left: 56px;

          div {

            // background-color: #fff;
            // img {
            //   width: 170px;
            //   height: 170px;
            //   border-radius: 50%;
            // }
            .box-img {
              // width: 180px;
              // height: 180px;
              width: 170px;
              height: 170px;
              position: relative;
              cursor: pointer;

              // overflow: hidden;
              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
              }

              span {
                display: none;
              }
            }

            .box-img:hover span {
              // display: block;
              position: absolute;
              // top: 160px;
              bottom: 0px;
              left: 0px;
              // left: 20%;
              // width: 180px;
              // height: 180px;
              width: 170px;
              height: 170px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              // background-color: RGBA(156, 142, 240, 0.57);
              background-color: rgba(255, 159, 163, 0.57);

              // transition: transform linear 0.3s;
              color: #fff;
              font-size: 18px;
              // text-align: center;
            }
          }
        }

        .box6 {
          width: 260px;
          height: 260px;
          // background-color: greenyellow;
          position: absolute;
          right: 10px;
          top: 390px;

          div {

            // background-color: #fff;
            // img {
            //   width: 240px;
            //   height: 240px;
            //   border-radius: 50%;
            // }
            .box-img {
              // width: 180px;
              // height: 180px;
              width: 160px;
              height: 160px;
              position: relative;
              cursor: pointer;

              // overflow: hidden;
              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
              }

              span {
                display: none;
              }
            }

            .box-img:hover span {
              // display: block;
              position: absolute;
              // top: 160px;
              bottom: 0px;
              left: 0px;

              // width: 180px;
              // height: 180px;
              width: 160px;
              height: 160px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              // background-color: RGBA(156, 142, 240, 0.57);
              background-color: rgba(255, 159, 163, 0.57);

              // transition: transform linear 0.3s;
              color: #fff;
              font-size: 18px;
              // text-align: center;
            }
          }
        }
      }

      .btn-right {
        // margin-left: 283px;
        // width: 312px;
        width: 350px;
        text-align: right;
        margin-top: 107px;

        .right-title {
          font-size: 30px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #fae0e7;
          line-height: 42px;
        }

        .right-hui {
          font-size: 36px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 50px;
        }

        .right-dan {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #adadad;
          line-height: 22px;
          margin-bottom: 79px;
        }

        ul {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          flex-wrap: wrap;
          margin-left: 15px;
          // width: 350px;
        }

        ul li {
          cursor: pointer;
          margin: 10px 0;
          width: 66px;
          height: 31px;
          margin-left: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #ffffff;
          border-radius: 16px;
          border: 1px solid #eaebed;
        }

        li.active {
          background-color: #fd686eff;
          color: #fff;
        }
      }
    }
  }

  //指示图
  .banner-ZS {
    width: 100%;
    // height: 500px;
    overflow: hidden;
    position: relative;
    background-position: center center;

    img {
      width: 100%;
      height: 100%;
      // @media screen {
      //   min-width: 1920px;
      // }
    }
  }

  //也在服务
  .v {
    display: flex;
    justify-content: center;

    .yezai-service {
      width: 1198px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .service-title {
        margin-top: 100px;
        width: 1198px;

        .service-title-a {
          width: 100%;
          height: 42px;
          font-size: 30px;
          font-weight: 600;
          color: #fae0e7;
          line-height: 42px;
        }

        .service-title-b {
          width: 100%;
          width: 500px;

          height: 50px;
          font-size: 36px;
          font-weight: bold;
          color: #333333;
          line-height: 50px;
        }

        .service-title-c {
          width: 100%;
          height: 22px;
          font-size: 16px;
          color: #adadad;
          line-height: 22px;
          margin-bottom: 10px;
        }
      }

      ul {
        li {
          margin-bottom: 50px;

          .head {
            margin-bottom: 20px;

            .lie {
              p {
                margin-left: 20px;
                // margin-top: 24px;
              }

              p:nth-child(1) {
                margin-left: 20px;
                margin-top: 18px;
                width: 100%;
                // width: 184px;
                height: 30px;
                font-size: 22px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                font-weight: bold;
                color: #333333;
                line-height: 30px;
                letter-spacing: 1px;
              }

              p:nth-child(2) {
                width: 100%;
                height: 20px;
                font-size: 14px;
                color: #adadad;
                line-height: 20px;
              }
            }
          }

          .li-img {
            width: 499px;
            height: 290px;
          }
        }

        li:nth-child(odd) {
          // top: -392px;
          float: left;

          .head {
            display: flex;

            .a {
              width: 82px;
              height: 81px;
              background: linear-gradient(142deg, #ffa1a7 0%, #fd686e 100%);
              border-radius: 0px 57px 57px 57px;
              display: flex;
              justify-content: center;
              align-items: center;

              span {
                position: absolute;
                display: flex;
                font-size: 36px;
                color: #ffffff;
                font-weight: bold;
              }
            }
          }
        }

        li:nth-child(2) {
          margin-top: 200px;
        }

        li:nth-child(even) {
          float: right;

          .head {
            display: flex;

            .a {
              width: 82px;
              height: 81px;
              background: linear-gradient(142deg, #ffa1a7 0%, #fd686e 100%);
              border-radius: 57px 0px 57px 57px;
              display: flex;
              justify-content: center;
              align-items: center;

              span {
                position: absolute;
                display: flex;
                font-size: 36px;
                color: #ffffff;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }

  //海报002
  .m-handsome-boy {
    // background-color: #fa5858;
    // height: 720px;
    width: 100%;
    // height: 500px;
    height: 550px;
    overflow: hidden;
    background-position: center center;

    img {
      width: 100%;
      height: 100%;

      @media screen {
        min-width: 1920px;
      }
    }
  }

  //自助服务
  .m-self {
    display: flex;
    justify-content: center;
    margin-top: 345px;
    height: 570px;
    background-color: #fdf0f3ff;

    .m-self-service {
      width: 1198px;
      position: relative;
      display: flex;
      justify-content: flex-end;

      .self-video {
        position: absolute;
        bottom: 20px;
        left: 0px;
        // width: 348px;
        // width: 380px;
        width: 380px;
        // height: 728px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;
        padding: 20px;
        // padding-top: 20px;
        // padding-bottom: 20px;
        border-radius: 40px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.14);
        box-sizing: border-box;

        video {
          border-radius: 10px;
          width: 328px;
        }

        img {
          border-radius: 32px;
          // width: 328px;
          // width: 380px;
          width: 348px;
          // height: 728px;
        }
      }

      .video-btn {
        display: flex;
        align-items: center;

        ul {
          .ac {
            height: 94px;
          }

          li:nth-child(1).ac {
            margin-top: 12px;
            height: 94px;
          }

          li {
            display: flex;
            align-items: center;
            width: 440px;
            height: 60px;
            margin-bottom: 18px;
            border-radius: 46px;
            background-color: #ffffff;
            display: flex;
            align-items: center;
            cursor: pointer;
            // transform: all .5s ease-out .2s;

            .icon {
              // width: 32px;
              // height: 32px;
              margin-left: 30px;
              // background: url(../../assets/images/login/jz.png) no-repeat;
              // background-size: 100%;
            }

            .titles {
              margin-left: 42px;
              height: 30px;
              font-size: 22px;
              color: #ccccccff;
            }

            .title {
              margin-left: 42px;
              // border-radius: 4px;
              // width: 112px;
              height: 30px;
              font-size: 22px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #333333;
              line-height: 30px;
              letter-spacing: 6px;
              display: flex;
              // align-items: center;
              justify-content: center;
              flex-direction: column;

              .sub-title {
                opacity: 0;
                font-size: 16px;
                line-height: 22px;
              }

              p:nth-child(1) {
                height: 30px;
                font-size: 22px;

                font-weight: 500;
                color: #333333;
                line-height: 30px;
                letter-spacing: 6px;
                margin-top: 15px;
              }

              p:nth-child(2) {
                height: 22px;
                font-size: 16px;
                color: #adadad;
                line-height: 22px;
                letter-spacing: 4px;
              }
            }
          }
        }
      }

      .video-title {
        text-align: right;
        position: absolute;
        top: -190px;
        right: 0px;

        p:nth-child(1) {
          height: 42px;
          font-size: 30px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #fae0e7;
          line-height: 42px;
        }

        p:nth-child(2) {
          height: 50px;
          font-size: 36px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 50px;
        }

        p:nth-child(3) {
          height: 22px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #adadad;
          line-height: 22px;
        }
      }
    }
  }

  //海报图003
  .m-self-help {
    margin-top: 55px;
    width: 100%;
    overflow: hidden;
    background-position: center center;

    img {
      width: 100%;
      height: 100%;
      // @media screen {
      //   min-width: 1920px;
      // }
    }
  }

  //文字
  .Slogan {
    display: flex;
    justify-content: center;
    margin-top: 100px;
    margin-bottom: 55px;

    .yezai-Slogan {
      width: 907px;
      height: 364px;
      position: relative;

      .yezai-title {
        position: absolute;
        top: 0px;
        left: 0px;
        font-size: 230px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #fdf0f3;
        line-height: 364px;
        letter-spacing: 10px;
      }

      p:nth-child(2) {
        height: 65px;
        font-size: 46px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 65px;
        letter-spacing: 1px;
        position: absolute;
        top: 220px;
        left: 345px;
      }

      p:nth-child(3) {
        font-size: 28px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #666666;
        line-height: 40px;
        letter-spacing: 9px;
        position: absolute;
        top: 299px;
        left: 436px;
      }
    }
  }

  // 列表
  .Successful-cases {
    //------------------
    margin: 0 70px;
    overflow: hidden;
    margin-top: -25px;
    display: flex;
    justify-content: center;
    justify-content: flex-start;

    // margin-left: 60px;
    ul {
      // display: flex;

      // margin: 0 auto;
      display: flex;
      justify-content: center;

      width: 2400px;
      padding: 25px 0;
      padding-bottom: 120px;
      transition: transform ease-in-out 0.4s;

      //该----
      li {
        // flex: 1;
        cursor: pointer;
        width: 280px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 20px;
        height: 400px;
        transition: all ease-in-out 0.2s;

        .list-center {
          margin: 0 auto;
        }

        .love-story-list {
          width: 2400px;
          padding: 25px 0;
          transition: transform ease-in-out 0.4s;
        }

        .love-title {
          margin: 20px 0 9px;
          font-size: 20px;
          line-height: 28px;
          color: #333;
          padding-left: 8px;
          padding-right: 8px;
          font-weight: normal;
        }

        .love-desc {
          font-size: 16px;
          line-height: 22px;
          color: #999;
          padding-left: 8px;
          padding-right: 8px;
          overflow: hidden;
          // 下面是显示几行写数字几就行
          -webkit-line-clamp: 5;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
        }

        .love-img {
          position: relative;
        }

        .love-img img {
          width: 100%;
          // height: 100%;
          height: 210px;
        }

        .love-review {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          transition: opacity linear 0.3s;
        }

        .review-bg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          /* opacity: 0; */
          background-color: rgba(255, 159, 163, 0.8);
          /* transition: opacity linear .5s; */
        }

        .review-btn {
          display: block;
          width: 204px;
          height: 50px;
          color: #fff;
          border: 1px solid #fff;
          position: absolute;
          z-index: 2;
          border-radius: 4px;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          right: 0;
          margin: auto;
          line-height: 50px;
          text-align: center;
          font-size: 18px;
        }

        .goto-icon {
          // background: url(../images/arrow.b33f28b.png) no-repeat;
          width: 22px;
          display: inline-block;
          height: 22px;
          margin-left: 20px;
          vertical-align: middle;
        }
      }

      li:last-child {
        margin-right: 0;
      }

      li:hover {
        transform: translateY(-3px);
        // box-shadow: 0px 7px 15px 0px rgba(255, 159, 163, 0.7);
        box-shadow: 0px -6px 15px 0px rgba(255, 159, 163, 0.7);

        // transform: translateY(-3px);
        // box-shadow: 0px -4px 25px 0px rgba(255, 159, 163, 50%);
      }

      li:hover .love-review {
        opacity: 1;
        transition: opacity linear 0.3s;
      }

      li:hover .review-bg {
        opacity: 1;
        transition: opacity linear 0.3s;
      }

      li:hover .love-desc {
        transform: translateY(-1px);
      }
    }
  }

  //指尖
  .m-get-marry::after,
  .m-get-marry::before {
    clear: both;
    display: block;
    content: "";
  }

  .m-get-marry {
    // height: 400px;
    width: 100%;
    text-align: center;
    color: #fff;

    // box-sizing: border-box;
    // display: flex;
    h4 {
      font-size: 30px;
      line-height: 42px;
      margin-bottom: 15px;
      font-weight: 500;
    }

    p {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 40px;
    }

    .b-get-app {
      width: 62.39583%;
      height: 354px;
      padding-top: 53px;
      background: url("../../assets/images/banner/banner-left.png") 100% no-repeat;
      background-size: 100% 100%;
      float: left;
      box-sizing: border-box;
      span {
        font-size: 16px;
        line-height: 22px;
        color: #fff;
      }

      .qr-code {
        width: 136px;
        height: 136px;
        border: 3px solid #fff;
        margin: 0 auto 12px;

        img {
          vertical-align: bottom;
          max-width: 100%;
        }
      }
    }

    .b-get-another {
      // height: 100%;
      height: 354px;
      padding-top: 72px;
      background: url("../../assets/images/banner/banner-right.png") 100% no-repeat;
      // background-size: 100% 100%;
      width: 36.92708%;
      // flex: 1;
      float: right;
      box-sizing: border-box;

      .goto-register {
        display: block;
        width: 257px;
        height: 60px;
        color: #fff;
        border: 1px solid #fff;
        z-index: 2;
        border-radius: 4px;
        margin: auto;
        line-height: 60px;
        text-align: center;
        font-size: 20px;
      }

      .goto-icon {
        // background: url(../images/arrow.b33f28b.png) no-repeat;
        width: 22px;
        display: inline-block;
        height: 22px;
        margin-left: 20px;
        vertical-align: middle;
      }
    }
  }

  .m-get-marryP {
    display: flex;
    width: 100%;
    .marryP-left {
      flex: 2;
      height: 400px;
      margin-right: 16px;
      position: relative;

      img {
        width: 100%;
        height: 400px;
        @media screen {
          min-width: 100%;
        }
      }

      .marryP-left-title {
        position: absolute;
        left: 40%;
        top: 15%;
        text-align: center;
        color: #fff;
        h4 {
          font-size: 30px;
          line-height: 42px;
          margin-bottom: 15px;
          font-weight: 500;
        }

        p {
          font-size: 20px;
          line-height: 28px;
          margin-bottom: 28px;
        }

        span {
          font-size: 16px;
          line-height: 22px;
          color: #fff;
        }

        .qr-code {
          width: 136px;
          height: 136px;
          border: 3px solid #fff;
          margin: 0 auto 12px;

          img {
            vertical-align: bottom;
            max-width: 100%;
            height: 136px;
          }
        }
      }
    }

    .marryP-right {
      flex: 1;
      height: 400px;
      position: relative;

      img {
        width: 100%;
        height: 400px;

        @media screen {
          min-width: 100%;
        }
      }

      .marryP-right-title {
        position: absolute;
        top: 20%;
        left: 25%;
        text-align: center;
        color: #fff;
        h4 {
          font-size: 30px;
          line-height: 42px;
          margin-bottom: 15px;
          font-weight: 500;
        }

        p {
          font-size: 20px;
          line-height: 28px;
          margin-bottom: 28px;
        }

        .goto-register {
          display: block;
          width: 257px;
          height: 60px;
          color: #fff;
          border: 1px solid #fff;
          z-index: 2;
          border-radius: 4px;
          margin: auto;
          line-height: 60px;
          text-align: center;
          font-size: 20px;
        }

        .goto-icon {
          // background: url(../images/arrow.b33f28b.png) no-repeat;
          width: 22px;
          display: inline-block;
          height: 22px;
          margin-left: 20px;
          vertical-align: middle;
        }
      }
    }
  }
}

// .head, .service-item-show .show-img
.service-item-show {
  animation: fadeInUp ease-in-out 0.8s;
  animation-fill-mode: forwards;
  opacity: 0;
}

// .head,.service-item-hide .show-img
.service-item-hide {
  animation: fadeOutDown ease-in-out 0.8s;
  animation-fill-mode: forwards;
  opacity: 1;
}

@keyframes bgBrans {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}
</style>
