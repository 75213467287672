<template>
    <div>
        <Header v-if="iSheader" />
        <Nlh v-else />
        <div class="companyNews">
            <div class="banner">
                <!-- <div class="phone">
                    <p>联系电话: <span>400 8234 521</span></p>
                    <div>申请会员服务</div>
                </div> -->
            </div>
            <div class="news">
                <p class="newsTitle">{{ title }}</p>
                <div class="newsList" v-for="item in newsList" :key="item.id" @click="jump(item)">
                    <img :src="'https://yezai-prod.oss-cn-shenzhen.aliyuncs.com/' + item.coverImg" class="newsImg" alt="">
                    <!-- <div class="newsImg">

                    </div> -->

                    <div class="newsContent">
                        <div class="newsContent_title">
                            {{ item.articleTitle }}
                        </div>
                        <!-- v-html="item.pubText" -->
                        <div class="newsContent_con" >
                            {{ item.articleDesc }}
                        </div>

                        <div class="newsContent_time">
                            {{ item.createTime.slice(0, 10) }}
                        </div>
                    </div>


                </div>
                <div class="newsPaging" v-if="iSpage">
                    <!-- <div class="newsPagingLeft" @click="PageAdd"> 上一页</div>
                    <div class="newsPagingPage">{{ pageNum }}</div>
                    <div class="newsPagingLeft" @click="PageReduce">下一页</div> -->

                    <el-pagination v-if="newsList.length > 0" layout="prev, pager, next" :prev-text="'上一页'"
                        :next-text="'下一页'" @prev-click="PageAdd" @next-click="PageReduce" :total="total" :page-size="10"
                        @current-change="currentChange">
                    </el-pagination>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Footer from "@/components/layout/footer";
import Header from "@/components/layout/header";
import Nlh from "@/components/layout/nlh";
import local from "@/api/common/local.js";
import { newsListPage } from "@/api/my/my.js";

export default {
    metaInfo: {
        title: "也在网-婚恋公司最新动态资讯汇总",
        // meta: [
        //     { charset: "utf-8" },
        //     {
        //         name: "viewport",
        //         content:
        //             "width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no",
        //     },
        //     {
        //         name: "keywords",
        //         content: "交友,交友网,征婚交友,网上交友,征婚,征婚网,征婚交友网,交友中心,婚恋交友,同城交友,离异征婚,同城约会吧,约会吧征婚,约会吧交友,北京交友,佛山交友,找对象,婚介,二婚,再婚,也在网,也在"
        //     }, {
        //         name: "description",
        //         content: "也在网创始于2016年，也在网红娘均经过专业的婚恋学，心理学培训，结合多年的征婚服务经验，给您提供用心细致，专业个性化的红娘服务。也在网：成就天下姻缘！"
        //     }
        // ],
    },
    props: ["title", "number"],
    data() {
        return {
            iSheader: true,
            newsList: [],
            pageNum: 1,
            iSpage: false,
            pages: 2,
            total:0,
        };
    },
    components: {
        Footer,
        Header,
        Nlh,
    },
    created() {

        let Data = local.get("access_token");
        if (Data) {
            this.iSheader = true;
        } else {
            this.iSheader = false;
        }
        this.getdate(this.pageNum)
    },
    methods: {
        async getdate(pageNum) {
            const { code, data } = await newsListPage({
                "categoryId": this.number,
                "pageNum": pageNum,
                // "pageSize": 1
            });
            if (code == 0) {
                this.newsList = data.records;
                this.iSpage = true;
                this.pageNum = data.current;
                this.pages = data.pages;
                this.total = data.total
            }
        },
        PageAdd() {
            if (this.pageNum > 1) {
                this.pageNum--;
                this.getdate(this.pageNum)
            }
        },
        PageReduce() {
            if (this.pageNum < this.pages) {
                this.pageNum++;
                this.getdate(this.pageNum)
            }
        },
        currentChange(val) {
            this.pageNum = val;
            this.getdate(this.pageNum)
        },
        jump(item) {
            console.log("_", item)
            // let rel = this.$router.resolve({
            //     path: "/articleDetails",
            //     query: item
            // });
            // window.open(rel.href, "_blank");
            let rel = this.$router.resolve({
                path: "/articleDetails",
                query: {
                    pageNum: this.pageNum,
                    pages: this.pages,
                    id: item.id,
                    artcileId: 9
                }
            });
            window.open(rel.href, "_blank");
        }
    },
};
</script>

<style lang="less" scoped>
.companyNews {
    width: 100%;
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 1200px;

    .banner {
        width: 100%;
        height: 466px;
        background: url("../../assets/images/aboutUs/about.png") no-repeat;
        background-size: 100% 100%;
        position: relative;
    }

    .news {
        width: 1000px;
        margin: 0 auto;
        position: relative;
        padding-top: 30px;

        .newsTitle {
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FD686E;
            line-height: 22px;
            position: relative;
            margin-bottom: 30px;

        }

        .newsTitle::after {
            content: "";
            position: absolute;
            bottom: -10px;
            left: 0px;
            width: 1000px;
            height: 1px;
            background-color: #ECECEC;
        }

        .newsList {
            display: flex;
            padding: 0 30px;
            box-sizing: border-box;
            margin-bottom: 50px;
            cursor: pointer;

            .newsImg {
                width: 260px;
                height: 146px;
                background: #000;
                margin-right: 30px;
                border-radius: 10px;
            }

            .newsContent {
                width: 630px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;

                .newsContent_title {

                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 22px;
                }

                .newsContent_con {
                    overflow: hidden;
                    -webkit-line-clamp: 2;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;

                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 20px;
                }

                .newsContent_time {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #999999;
                    line-height: 20px;
                }
            }
        }

        .newsPaging {
            display: flex;
            justify-content: center;
            margin-top: 40px;

            .newsPagingLeft {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 20px;
                cursor: pointer;
            }

            .newsPagingPage {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FD686E;
                line-height: 20px;
                margin: 0 28px;
            }

            /deep/ .btn-prev {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 20px;
                cursor: pointer;
            }



            /deep/ .el-pager li.active {
                font-size: 14px;
                color: #FD686E;
                // line-height: 20px;
                cursor: pointer;
            }

            /deep/ .el-pager li.number {
                font-size: 14px;
            }

            /deep/ .el-pager li:hover {
                color: #FD686E;
            }

            /deep/ .el-pagination button:hover {
                color: #FD686E;
            }
        }

    }
}
</style>