<template>
    <div>
        <Header v-if="iSheader" />
        <Nlh v-else />
        <div class="companyNews">
            <div class="banner">
            </div>
            <div class="articleDetails">
                <div class="article_header">
                    <p class="article_Tile">{{ article.articleTitle }}</p>
                    <p class="article_TimeAndAuthor">发表时间：{{ article.createTime }} 作者：也在网</p>
                </div>
                <div class="article_content">
                    <div class="article_content_o  ql-snow ql-editor" style="white-space:pre-wrap;"
                        v-html="article.pubText">

                    </div>
                </div>
                <div class="newsPaging" v-if="iSpage">
                    <div class="newsPagingLeft" @click="PageAdd" v-if="isTop">
                        <p class="newsPagingLeft_btn">上一篇</p>
                        <!-- <p class="newsPagingLeft_title">{{ recommendList[0].articleTitle }}</p> -->
                    </div>
                    <div class="newsPagingLeft" style="margin-top:32px;" @click="PageReduce" v-if="isBottom">
                        <p class="newsPagingLeft_btn">下一篇</p>
                        <!-- <p class="newsPagingLeft_title">{{ recommendList[2].articleTitle }}</p> -->
                    </div>
                </div>
            </div>
            <div class="Feedback">
                <textarea class="Feedback-text" placeholder="暂无评论，快来发表吧～" v-model="tempText" name="" id="" cols="30"
                    rows="10"></textarea>
                <div class="Feedback_btn">
                    <div class="Feedback_btn_left">
                        <p>昵称: </p><input class="" v-model="tempName" />
                        <p class="Feedback_btn_left_i">验证码:</p><input v-model="tempYzm" /> <img
                            class="Feedback_btn_left_img" :src="YzmImg" @click="getYzm()" style="" alt="">
                    </div>
                    <div class="Feedback_btn_right" @click="btn">
                        <p>提交评论</p>
                    </div>
                </div>
                <div class="Feedback_conent">
                    <p class="Feedback_conent_title">全部评论（0条）</p>
                    <div class="CommentEmpty">
                        <img class="CommentEmpty_prompt" :src="CommentEmpty" alt="">
                        <p class="CommentEmpty_text">亲～快来评论噢</p>
                    </div>
                </div>
            </div>
            <div class="news">
                <div class="newsTitle">
                    <p class="newsTitle_temp">推荐文章</p>
                    <img class="newsTitle_img" :src="rightIcon" alt="" @click="jumpArticle">
                </div>
                <div class="newsList" v-for="(item, index) in recommendList" :key="index" @click="jump(item, index)">
                    <div class="newsListLeft">
                        {{ item.articleTitle }}
                    </div>
                    <div class="newsListRight">
                        {{ item.createTime.slice(0, 10) }}
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Footer from "@/components/layout/footer";
import Header from "@/components/layout/header";
import Nlh from "@/components/layout/nlh";
import local from "@/api/common/local.js";
import { newsListPage } from "@/api/my/my.js";
import { Yzm, YzmValid, articleDetails } from "@/api/my/my.js";
import "@/assets/css/quill.snow.css"
import "@/assets/css/quill.bubble.css"
import "@/assets/css/quill.core.css"
export default {
    metaInfo: {
        title: "也在网-婚恋公司最新文章详情",
        // meta: [
        //     { charset: "utf-8" },
        //     {
        //         name: "viewport",
        //         content:
        //             "width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no",
        //     },
        //     {
        //         name: "keywords",
        //         content: "交友,交友网,征婚交友,网上交友,征婚,征婚网,征婚交友网,交友中心,婚恋交友,同城交友,离异征婚,同城约会吧,约会吧征婚,约会吧交友,北京交友,佛山交友,找对象,婚介,二婚,再婚,也在网,也在"
        //     }, {
        //         name: "description",
        //         content: "也在网创始于2016年，也在网红娘均经过专业的婚恋学，心理学培训，结合多年的征婚服务经验，给您提供用心细致，专业个性化的红娘服务。也在网：成就天下姻缘！"
        //     }
        // ],
    },
    props: ["title", "number"],
    data() {
        return {
            iSheader: true,
            newsList: [],
            recommendList: [],
            pageNum: 1,
            iSpage: false,
            pages: 2,
            article: {},
            index: 0,
            YzmImg: '',
            Yzm: '',
            CommentEmpty: require('@/assets/images/server/CommentEmpty.png'),
            rightIcon: require('@/assets/images/server/right.png'),
            artcileId: 9,
            tempText: '',
            tempName: '',
            tempYzm: '',
            isTop: false,
            isBottom: false
        };
    },
    components: {
        Footer,
        Header,
        Nlh,
    },
    created() {
        let Data = local.get("access_token");
        if (Data) {
            this.iSheader = true;
        } else {
            this.iSheader = false;
        }
        this.getYzm()
        this.getdate()
        if (this.$route.query.id) {
            this.getArcticleDetails()
        }
    },
    methods: {
        async btn() {
            if (this.tempText != '' && this.tempName != '' && this.tempYzm != '') {

                const { code } = await YzmValid({
                    code: this.tempYzm,
                });
                if (code == 0) {
                    this.$message({
                        message: "评论成功!",
                        type: "success",
                        offset: 100,
                    });
                } else {
                    this.$message({
                        message: "验证码验证失败",
                        type: "error",
                        offset: 100,
                    });
                }

            } else {

                let temp = [this.tempText, this.tempName, this.tempYzm];
                let emptyIndex = temp.indexOf('');
                let Prompt = ['评论为空', '昵称为空', '验证码为空']
                this.$message({
                    message: Prompt[emptyIndex],
                    type: "error",
                    offset: 100,
                });
                // console.log("_",emptyIndex)

            }
            // let a = {
            //     code: this.Yzm,
            // };
            // const { code } = await YzmValid(a);
            // console.log("_", code)
        },
        async getYzm() {
            let a = Date.parse(new Date());
            this.YzmImg = `https://api.yezai.love/system-server/sys/image_code/gen?t=${a}`;
            await Yzm(a);
        },
        async getArcticleDetails() {
            // /sys/art/info/{id}
            const { data } = await articleDetails(this.$route.query.id)
            if (this.$route.query.id) {
                this.article = data
            }

        },
        async getRecommendList(pageNum, artcileId) {
            const { code, data } = await newsListPage({
                "categoryId": artcileId,
                "pageNum": pageNum,
                "pageSize": 10
            });
            if (code == 0) {
                this.recommendList = data.records;
            }
        },

        async getdate() {
            let artcileId = this.$route.query.artcileId;
            let pageNum = this.$route.query.pageNum;
            this.pages = this.$route.query.pages;
            let id = this.$route.query.id;
            const { code, data } = await newsListPage({
                "categoryId": artcileId,
                "pageNum": pageNum,
                "id": id,
                "pageSize": 1
            });
            if (code == 0) {
                this.newsList = data.records;
                if (!this.$route.query.id) {
                    this.article = this.newsList[0]
                }


                this.iSpage = true;
                this.pageNum = data.current;
                this.pages = data.pages;
                let randomNumber = Math.floor(Math.random() * this.$route.query.pages) + 1;
                this.getRecommendList(randomNumber, artcileId)
                if (this.pageNum == 1) {
                    this.isTop = false
                } else {

                    this.isTop = true
                }

                if (this.pageNum == this.pages) {
                    this.isBottom = false
                } else {
                    this.isBottom = true
                }
            }
        },
        PageAdd() {
            if (this.pageNum >= 1) {
                // if(this.newsList.length)
                this.pageNum--;
                let rel = this.$router.resolve({
                    path: "/articleDetails",
                    query: {
                        pageNum: this.pageNum,
                        pages: this.$route.query.pages,
                        artcileId: this.$route.query.artcileId
                    }
                });
                window.open(rel.href, "_blank");
            }
        },
        PageReduce() {
            if (this.pageNum <= this.pages) {
                this.pageNum++;
                let rel = this.$router.resolve({
                    path: "/articleDetails",
                    query: {
                        pageNum: this.pageNum,
                        pages: this.$route.query.pages,
                        artcileId: this.$route.query.artcileId
                    }
                });
                window.open(rel.href, "_blank");
            }
        },
        jump(item) {
            let rel = this.$router.resolve({
                path: "/articleDetails",
                query: {
                    pageNum: this.pageNum,
                    pages: this.$route.query.pages,
                    id: item.id,
                    artcileId: this.$route.query.artcileId
                }
            });
            window.open(rel.href, "_blank");
        },
        jumpArticle() {
            let rel = this.$router.resolve({
                path: "/articleList"
            });
            window.open(rel.href, "_blank");
        }
    },
};
</script>

<style lang="less" scoped>
.companyNews {
    width: 100%;
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 1200px;

    .banner {
        width: 100%;
        height: 466px;
        background: url("../../assets/images/aboutUs/about.png") no-repeat;
        background-size: 100% 100%;
        position: relative;
    }

    .articleDetails {
        width: 1200px;
        margin: 0 auto;

        .article_header {
            margin-bottom: 30px;
            padding-left: 33px;
            padding-top: 30px;
            box-sizing: border-box;

            .article_Tile {
                font-size: 24px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 33px;
                margin-bottom: 20px;
            }

            .article_TimeAndAuthor {

                font-size: 13px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ADADAD;
                line-height: 18px;
                margin-bottom: 20px;
            }
        }

        .article_header::after {
            content: "";
            width: 1200px;
            display: flex;
            height: 1px;
            background-color: #ECECEC;
            position: relative;
            // bottom: -10px;
            left: -33px;
        }

        .article_content {
            // padding-bottom: 20px;
            margin-bottom: 50px;
            box-sizing: border-box;

            .article_content_o {
                padding-left: 30px;
                padding-right: 30px;

                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 2;


                //   /deep/  .ql-align-center   {
                //     text-align: center;
                //     // margin: 0 auto;

                //     // display: flex;
                //     // justify-content: center;
                //         // font-size: 20px;
                //         // font-family: PingFangSC-Regular, PingFang SC;
                //         // font-weight: 400;
                //         // color: #FD686E;
                //         // line-height: 50px;
                //     }
                /deep/ .ql-align-center img {
                    max-width: 1140px;
                }

                /deep/ p img {
                    // text-indent: 2em;
                    max-width: 1140px;
                }
            }
        }

        .article_content::after {
            content: '';
            display: flex;
            width: 1260px;
            height: 1px;
            background-color: #ECECEC;
            position: relative;
            bottom: -20px;
        }

        .newsPaging {
            padding-left: 15px;
            box-sizing: border-box;
            margin-bottom: 70px;

            .newsPagingLeft {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 20px;
                display: flex;
                margin-top: 10px;

                .newsPagingLeft_btn {
                    margin-right: 30px;
                    cursor: pointer;
                }
            }
        }

    }

    .news {
        width: 1200px;
        margin: 0 auto;
        position: relative;
        padding-top: 30px;

        .newsTitle {
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FD686E;
            line-height: 22px;
            position: relative;
            margin-bottom: 30px;
            display: flex;
            justify-content: space-between;
            padding: 30px 15px 10px 15px;

            .newsTitle_img {
                width: 20px;
                height: 20px;
                cursor: pointer;
            }

        }

        .newsTitle::after {
            content: "";
            position: absolute;
            bottom: -10px;
            left: 0px;
            width: 1200px;
            height: 1px;
            background-color: #ECECEC;
        }

        .newsList {
            display: flex;
            padding: 0 30px;
            padding: 20px 0;
            box-sizing: border-box;
            align-items: center;
            justify-content: space-between;
            // margin-bottom: 50px;
            width: 1160px;
            margin: 0 auto;
            border-bottom: 1px dashed #ECECEC;
            cursor: pointer;


            .newsListLeft {
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 22px;
                width: 500px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                margin-left: 10px;
            }

            .newsListRight {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                line-height: 20px;
                margin-right: 10px;
            }

        }

        .newsPaging {
            display: flex;
            justify-content: center;
            margin-top: 40px;

            .newsPagingLeft {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 20px;
                cursor: pointer;
            }

            .newsPagingPage {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FD686E;
                line-height: 20px;
                margin: 0 28px;
            }

            /deep/ .btn-prev {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 20px;
                cursor: pointer;
            }



            /deep/ .el-pager li.active {
                font-size: 14px;
                color: #FD686E;
                // line-height: 20px;
                cursor: pointer;
            }

            /deep/ .el-pager li.number {
                font-size: 14px;
            }

            /deep/ .el-pager li:hover {
                color: #FD686E;
            }

            /deep/ .el-pagination button:hover {
                color: #FD686E;
            }
        }

    }

    .Feedback {
        width: 1170px;
        margin: 0 auto;
        padding: 30px 35px;
        box-sizing: border-box;
        //         width: 1170px;
        // height: 800px;
        background: #F7F7F7;

        .Feedback-text {
            border: 1px solid #E9E9E9;
            width: 100%;
            height: 250px;
            padding: 14px 10px;
            box-sizing: border-box;
        }

        .Feedback_btn {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 16px;
            margin-bottom: 34px;

            .Feedback_btn_left {
                display: flex;
                align-items: center;

                p {
                    padding-right: 10px;
                    box-sizing: border-box;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 20px;
                }

                input {
                    width: 174px;
                    height: 40px;
                    background: #FFFFFF;
                    border: 1px solid #E9E9E9;
                    padding: 10px;
                    box-sizing: border-box;
                }

                .Feedback_btn_left_i {
                    margin-left: 30px;
                }

                .Feedback_btn_left_img {
                    width: 100px;
                    height: 35px;
                    cursor: pointer;
                    margin-left: 10px;
                }
            }

            .Feedback_btn_right {
                width: 200px;
                height: 40px;
                background: #FD686E;
                border-radius: 20px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }


        }

        .Feedback_conent {
            .Feedback_conent_title {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 20px;
                padding-bottom: 20px;
                box-sizing: border-box;
            }

            .Feedback_conent_title::after {
                content: '';
                display: flex;

                height: 1px;

                // width: 1200px;
                position: relative;
                bottom: -20px;

                background-color: #ECECEC;
            }

            .CommentEmpty {
                display: flex;
                // flex-wrap: wrap;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding-top: 98px;
                padding-bottom: 98px;
                box-sizing: border-box;

                .CommentEmpty_prompt {
                    width: 127px;
                    height: 147px;
                }

                .CommentEmpty_text {
                    margin-top: 15px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #999990;
                    line-height: 20px;
                    margin-right: 21px;
                }
            }
        }
    }
}
</style>