<template>
  <div class="footer">
    <div class="page">
      <div class="COPYRIGHT">
        <!-- <p>城市: <span @click="city">北京</span></p>
        <div class="line"></div> -->
        <p style="margin-top: 62px">
          <span @click="ju(8)">公司新闻</span>
          &nbsp;｜&nbsp;
          <span @click="ju(9)">情感资讯</span>
          &nbsp;｜&nbsp;
          <span @click="ju(0)">关于我们</span>
          &nbsp;｜&nbsp;

          <span @click="ju(1)">联系我们</span>
          &nbsp;｜&nbsp;

          <span @click="ju(2)">加入我们</span>
          &nbsp;｜&nbsp;

          <span @click="ju(3)">安全中心</span>
          &nbsp;｜&nbsp;

          <span @click="ju(4)">也在会员</span>
          &nbsp;｜&nbsp;

          <span @click="ju(5)">也在网服务协议</span>
          &nbsp;｜&nbsp;
          <!-- <router-link to="/m/portal/register/serverDeal"
            >个人信息保护政策</router-link
          > -->
          <span @click="ju(6)">个人信息保护政策</span>
          &nbsp;｜&nbsp;
          <span @click="ju(7)">品牌介绍</span>


        </p>
        <div class="line"></div>
        <p>
          品牌：9年专业婚恋服务 专业：庞大的资深红娘队伍
          客服热线：4008-234-521（周一到周日：9:00-21:00）
        </p>
        <p>Copyright ©2016-2025 版权所有：佛山市蝶与花信息技术有限公司</p>
        <p>
          增值电信业务经营许可证: 粤B2-20201225 &nbsp;
          <span @click="jump" style="cursor: pointer">
            粤ICP备20068138号</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import local from "@/api/common/local.js";
export default {
  methods: {
    jump() {
      window.open(`https://beian.miit.gov.cn/#/Integrated/index`, "_blank");
    },
    city(){
      this.$router.push({ name: 'city', params: { cityName: 'beijing' } });
    },
    ju(i) {
      var paths = [
        "/aboutUs",
        "/aboutUs",
        "/aboutUs",
        "/safe",
        "/n/yezaiMember",
        "/m/portal/register/prDeal",
        "/m/portal/register/serverDeal",
        "/introduce",
        "/companynews",
        "/emotionalcounsel"
      ];

      if (i >= 0 && i < paths.length) {
        if (i == 4) {
          if (local.get("access_token")) {
            let rel = this.$router.resolve({
              path: "/n/yezaiMember",
            });
            window.open(rel.href, "_blank");
          }
        } else {
          let rel = this.$router.resolve({
            path: paths[i],
          });
          window.open(rel.href, "_blank");
        }
      } else {
        // 处理无效的索引值
      }

    },
  },
};
</script>

<style lang="less" scoped>
.footer {
  display: flex;
  justify-content: center;

  .page {
    width: 1200px;
    line-height: 30px;
    text-align: center;

    .COPYRIGHT {
      margin-bottom: 42px;

      p {
        margin-top: 10px;

        span {
          cursor: pointer;
        }
      }

      .line {
        height: 1px;
        background-color: #e3e3e3;
        margin: 18px 0;
      }
    }
  }
}
</style>
