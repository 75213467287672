<template>
    <div>
        <Header v-if="iSheader" />
        <Nlh v-else />
        <div class="companyNews">
            <div class="banner">
            </div>
            <div class="news">
                <p class="newsTitle">文章列表</p>
                <div class="newsList" v-for="(item, index) in newsList" :key="index" @click="jump(item, index)">
                    <div class="newsListLeft">
                        {{ item.articleTitle }}
                    </div>
                    <div class="newsListRight">
                        {{ item.createTime.slice(0, 10) }}
                    </div>
                </div>
                <div class="newsPaging" v-if="iSpage">
                    <el-pagination layout="prev, pager, next" :prev-text="'上一页'" :next-text="'下一页'" @prev-click="PageAdd"
                        @next-click="PageReduce" :total="total" :page-size="10" @current-change="currentChange">
                    </el-pagination>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Footer from "@/components/layout/footer";
import Header from "@/components/layout/header";
import Nlh from "@/components/layout/nlh";
import local from "@/api/common/local.js";
import { newsListPage } from "@/api/my/my.js";

export default {
    metaInfo: {
        title: "也在网-婚恋公司最新文章列表",
        // meta: [
        //     { charset: "utf-8" },
        //     {
        //         name: "viewport",
        //         content:
        //             "width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no",
        //     },
        //     {
        //         name: "keywords",
        //         content: "交友,交友网,征婚交友,网上交友,征婚,征婚网,征婚交友网,交友中心,婚恋交友,同城交友,离异征婚,同城约会吧,约会吧征婚,约会吧交友,北京交友,佛山交友,找对象,婚介,二婚,再婚,也在网,也在"
        //     }, {
        //         name: "description",
        //         content: "也在网创始于2016年，也在网红娘均经过专业的婚恋学，心理学培训，结合多年的征婚服务经验，给您提供用心细致，专业个性化的红娘服务。也在网：成就天下姻缘！"
        //     }
        // ],
    },
    props: ["title", "number"],
    data() {
        return {
            iSheader: true,
            newsList: [],
            pageNum: 1,
            iSpage: false,
            pages: 2,
            total:0
        };
    },
    components: {
        Footer,
        Header,
        Nlh,
    },
    created() {
        let Data = local.get("access_token");
        if (Data) {
            this.iSheader = true;
        } else {
            this.iSheader = false;
        }
        this.getdate(this.pageNum)
    },
    methods: {
        async getdate(pageNum) {
            const { code, data } = await newsListPage({
                "categoryId": 9,
                "pageNum": pageNum,
                // "pageSize": 1
            });
            if (code == 0) {
                //         list.sort(function(a, b) {
                //   return b.createTime < a.createTime ? -1 : 1
                // })
                this.newsList = data.records;
                this.iSpage = true;
                this.pageNum = data.current;
                this.pages = data.pages;
                this.total = data.total;
            }
        },
        PageAdd() {
            if (this.pageNum > 1) {
                this.pageNum--;
                this.getdate(this.pageNum)
            }
        },
        PageReduce() {
            if (this.pageNum < this.pages) {
                this.pageNum++;
                this.getdate(this.pageNum)
            }
        },
        currentChange(val) {
            this.pageNum = val;
            this.getdate(this.pageNum)
        },
        jump(item, index) {
            let rel = this.$router.resolve({
                path: "/articleDetails",
                query: {
                    // list: JSON.stringify(this.newsList),
                    pageNum: this.pageNum,
                    pages: this.pages,
                    id: item.id,
                    index: index,
                    artcileId: 9
                }
            });
            window.open(rel.href, "_blank");
        }
    },
};
</script>

<style lang="less" scoped>
.companyNews {
    width: 100%;
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 1200px;

    .banner {
        width: 100%;
        height: 466px;
        background: url("../../assets/images/aboutUs/about.png") no-repeat;
        background-size: 100% 100%;
        position: relative;
    }

    .news {
        width: 1200px;
        margin: 0 auto;
        position: relative;
        padding-top: 30px;

        .newsTitle {
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FD686E;
            line-height: 22px;
            position: relative;
            margin-bottom: 10px;

        }

        .newsTitle::after {
            content: "";
            position: absolute;
            bottom: -10px;
            left: 0px;
            width: 1200px;
            height: 1px;
            background-color: #ECECEC;
        }

        .newsList {
            display: flex;
            padding: 0 30px;
            padding: 20px 0;
            box-sizing: border-box;
            align-items: center;
            justify-content: space-between;
            // margin-bottom: 50px;
            width: 1160px;
            margin: 0 auto;
            border-bottom: 1px dashed #ECECEC;
            cursor: pointer;


            .newsListLeft {
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 22px;
                width: 500px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                margin-left: 10px;
            }

            .newsListRight {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                line-height: 20px;
                margin-right: 10px;
            }

        }

        .newsPaging {
            display: flex;
            justify-content: center;
            margin-top: 40px;

            .newsPagingLeft {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 20px;
                cursor: pointer;
            }

            .newsPagingPage {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FD686E;
                line-height: 20px;
                margin: 0 28px;
            }

            /deep/ .btn-prev {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 20px;
                cursor: pointer;
            }



            /deep/ .el-pager li.active {
                font-size: 14px;
                color: #FD686E;
                // line-height: 20px;
                cursor: pointer;
            }

            /deep/ .el-pager li.number {
                font-size: 14px;
            }

            /deep/ .el-pager li:hover {
                color: #FD686E;
            }

            /deep/ .el-pagination button:hover {
                color: #FD686E;
            }
        }

    }
}
</style>