<template>
  <div>
    <Header v-if="iSheader" />
    <Nlh v-else />
    <div class="content">
      <div class="content-Img">
        <img src="../../assets/images/banner/anquan.png" alt="" />
      </div>
      <div class="content-o">
        <div class="content-title">安全中心</div>
      </div>
      <div class="tab">
        <div class="tab-header">
          <span class="default-tab" :class="{ 'active-tab': index == 1 }" @click="index = 1">
            举报投诉中心</span>
          <span class="default-tab" :class="{ 'active-tab': index == 2 }" @click="index = 2">
            安全指南</span>
          <span class="default-tab" :class="{ 'active-tab': index == 3 }" @click="index = 3">
            报警指南</span>
        </div>
        <div class="report-box" v-show="index == 1">
          <p class="report-desc">
            也在网始终致力于打造真实、纯净、安全的相亲网站。也在网联合“芝麻信用”让会员交友更安全，同时采用双向匿名邮件，有效的杜绝了开放通讯给会员隐私带来的隐患。
            尽管我们采取了种种防护措施，但网络诈骗花样繁出，因此我们提醒广大会员注意：与他人聊天过程中树立安全警觉意识，如您发现不诚信行为，请第一时间向我们进行投诉和举报，我们将严肃处理。对于违法情节严重、骗取金额较大的情形，建议您及时向公安机关报案。
          </p>
          <div class="report-flex">
            <div class="content-left">
              <p class="title" style="
                  font-size: 22px;
                  font-weight: bold;
                  color: #333;
                  margin-bottom: 10px;
                ">
                在线举报
              </p>
              <p>
                也在网在每位会员资料页右上角设置举报投诉入口，如遇不诚信行为，请前往点击“举报此人”按钮。
              </p>
              <p>举报热线：4008-234-521（周一至周日 9：00-21：00）</p>
            </div>
            <img src="../../assets/images/banner/jubao.png" alt="" />
          </div>
        </div>
        <div class="guid-box" v-show="index == 2">
          <div class="guid-desc">
            在双方确立正式婚姻关系之前，尽量避免与对方发生任何形式的钱财往来。对方如果向您提出钱财方面的请求，特别是数目较大的款项，请保持十二分警惕。以下是当下热门诈骗手法案例，请您务必认真阅读：
          </div>
          <!-- 01 -->
          <div class="contentL">
            <div class="bottom">
              <div class="right-content">
                <p class="title">01 <span>赌博/彩票诈骗</span></p>
                <p>
                  行骗者先将自己装扮成成功人士或海外技术工程师，通过嘘寒问暖骗取征婚对象的好感，并快速确认恋爱关系。确定关系后，行骗者会时不时在沟通中透露自己可以通过
                  “技术漏洞”或“后台操作”的方式控制一个博彩类或赌博游戏类的网站赚钱，并向征婚对象发送后台数据或提现短信炫耀自己能够稳赚不赔，让其深信不疑。最后引诱征婚对象一起投入，通过指导征婚对象将钱支付到网站平台上，但实际上网站显示的金额只是数字，行骗者早已将钱转走。
                </p>
                <p class="tips">也在网安全提示：</p>
                <p>
                  1、根据国家法律和政府规定，
                  <span class="strong">赌博和网络彩票是违法行为，以任何形式向您推销赌博或网络彩票业务的都是诈骗行为</span>，请保持警惕。
                </p>
              </div>
              <div class="img-box right-img">
                <!-- <img
                  src="//i.zhenai.com/pc/portal/safe/images/layer_safe_bet.d7e7d6f.png"
                  alt=""
                /> -->
                <img src="../../assets/images/safe/1.png" alt="" />
              </div>
            </div>
          </div>
          <!-- 02 -->
          <div class="contentL">
            <div class="bottom">
              <div class="img-box left-img">
                <!-- <img
                  src="//i.zhenai.com/pc/portal/safe/images/layer_safe_investment.724af28.png"
                  alt=""
                /> -->
                <img src="../../assets/images/safe/2.png" alt="" />
              </div>
              <div class="left-content">
                <p class="title">02 <span>投资诈骗</span></p>
                <p>
                  行骗者多将自己包装为成功人士，按照管用的套路，交流一段时间后取得征婚对象的信任，随后以帮助或建议的方式推荐征婚对象在指定平台（股票、基金、期货等投资项目）进行投资，接下来的情况可能有以下三类：1、若投资暂时成功，要求征婚对象加大投注，最后全部赔本；2、若投资成功后，征婚对象想要取现，则告知需缴纳一笔取现费，取现费缴纳后行骗者消失；3、若投资失败，则告知是没选好，再次推荐另一只，结果还是赔本。
                </p>
                <p class="tips">也在网安全提示：</p>
                <p>
                  请千万不要轻信任何陌生人介绍的投资渠道，
                  <span class="strong">尤其是承诺“稳赚不赔”、“一本万利”、“无风险”的投资渠道，</span>请一定保持警惕。
                </p>
              </div>
            </div>
          </div>
          <!-- 03 -->
          <div class="contentL">
            <div class="bottom">
              <div class="right-content">
                <p class="title">03 <span>假借贷</span></p>
                <p>
                  行骗者利用各式各样的借口向会员借钱，得手后立刻消失无影。惯常使用的借口有以下几种，请各位会员留心注意：
                </p>
                <p>
                  ·
                  以事业有成的形象出现，表明自己正经营着许多产业。突然发生了资金周转不灵的局面，需要借钱周转
                </p>
                <p>· 声称自己患病或遇到无法解决的困境，需要钱救急</p>
                <p>· 声称父母家人患病，医治需要很多金钱，需要借钱</p>
                <p>
                  ·
                  在约会过程中，来电谎称途中发生车祸，身边找不到其他人，需要钱入院
                </p>
              </div>
              <div class="img-box right-img">
                <!-- <img
                  src="//i.zhenai.com/pc/portal/safe/images/layer_safe_borrow.bb991c1.png"
                  alt=""
                /> -->
                <img src="../../assets/images/safe/3.png" alt="" />
              </div>
            </div>
          </div>
          <!-- 04 -->
          <div class="contentL">
            <div class="bottom">
              <div class="img-box left-img">
                <!-- <img
                  src="//i.zhenai.com/pc/portal/safe/images/layer_safe_flower.c6f5850.png"
                  alt=""
                /> -->
                <img src="../../assets/images/safe/4.png" alt="" />
              </div>
              <div class="left-content">
                <p class="title">04 <span>送花篮</span></p>
                <p>
                  该类行骗者多以中年男性并且事业有成的面貌出现，也有少数的女性骗子。这些人往往会声称自己刚刚经历过感情的波折，或者年龄已大，家里催逼结婚等。总之让人觉得他们有合理的借口急于同你确立关系，骗取你的信任。
                </p>
                <p>
                  这些人会非常频繁地与你保持联系，可能一天发几次短信，打几次电话。很快会以很亲密的称呼来拉近彼此的距离。最后甚至是找人假扮其父母给你打电话，确认婚事，进一步让你入局。然后往往以父亲生日、公司开业、店面开张为由，要送些礼金花篮之类的东西，骗取钱财，价值从几千到上万不等。
                </p>
              </div>
            </div>
          </div>
          <!-- 05 -->
          <div class="contentL">
            <div class="bottom">
              <div class="right-content">
                <p class="title">05 <span>收集个人信息</span></p>
                <p>
                  行骗者大多扮演成自身条件特别优秀的成功男士，行骗方式主要有以下两种：
                </p>
                <p>
                  1、
                  刚开始聊天就直接索要电话号码，并且以“很少上也在网”、“QQ和微信都不怎么用”为借口，得到号码后并不联系；
                </p>
                <p>
                  2、
                  将自身简介和手机号发给征婚对象，博取信任，并要求电话或短信联系，只要征婚对象拨打或短信，行骗者借此获得征婚对象的手机号码。。
                </p>
                <p>
                  行骗者得到联系方式后转给其他机构用于电话推销，以推销婚介服务居多。
                </p>
              </div>
              <div class="img-box right-img">
                <!-- <img
                  src="//i.zhenai.com/pc/portal/safe/images/layer_safe_persona.9f20fe2.png"
                  alt=""
                /> -->
                <img src="../../assets/images/safe/5.png" alt="" />
              </div>
            </div>
          </div>
          <!-- 06 -->
          <div class="contentL">
            <div class="bottom">
              <div class="img-box left-img">
                <!-- <img
                  src="//i.zhenai.com/pc/portal/safe/images/layer_safe_wine.0d14126.png"
                  alt=""
                /> -->
                <img src="../../assets/images/safe/6.png" alt="" />
              </div>
              <div class="left-content">
                <p class="title">06 <span>饭托酒托</span></p>
                <p>
                  这种骗术发生在双方见面约会阶段，是比较容易预防和识破的骗术，其常见表现为：
                </p>
                <p>·此类诈骗者无论男女，都具有不错的相貌条件</p>
                <p>·会主动邀约您或者十分愿意与您见面</p>
                <p>
                  ·会约您到不熟悉的消费场地见面。高明一点的会约您在一个比较常见的公共场所碰头，然后以散步等方式
                  “偶然” 或“不经意”的请您去路边的一家消费场所坐坐
                </p>
                <p>
                  ·到消费场所后，异常积极的点食物或饮料。会故意模糊由谁买单的问题甚至暗示由您买单
                </p>
                <p>·消费结束后，您收到的账单将异常昂贵</p>
              </div>
            </div>
          </div>
          <!-- 07 -->
          <div class="contentL">
            <div class="bottom">
              <div class="right-content">
                <p class="title">07 <span>骗取路费</span></p>
                <p>
                  这种骗术发生在异地交往之间。行骗者首先会假装与会员联系，交往到一定程度以后，会声称来看会员。之后会给会员打来电话，谎称钱包被盗或者信用卡透支，要求会员汇款来给他买票。也有在声称来看望会员后直接向会员索要路费，一旦会员给其汇款，骗子立刻携款销声匿迹。
                </p>
              </div>
              <div class="img-box right-img">
                <!-- <img
                  src="//i.zhenai.com/pc/portal/safe/images/layer_safe_way.7ec26f0.png"
                  alt=""
                /> -->
                <img src="../../assets/images/safe/7.png" alt="" />
              </div>
            </div>
          </div>
          <!-- 08 -->
          <div class="contentL">
            <div class="bottom">
              <div class="img-box left-img">
                <!-- <img
                  src="//i.zhenai.com/pc/portal/safe/images/layer_safe_talk.46a23c3.png"
                  alt=""
                /> -->
                <img src="../../assets/images/safe/8.png" alt="" />
              </div>
              <div class="left-content">
                <p class="title">08 <span>传销诈骗</span></p>
                <p>
                  这种骗术总是先声称自己的成就很好，事业基础不错，但在感情上却是一片空白，急于寻找伴侣，希望你来协助他（她）自己一起将事业做得更大，
                  行骗者会非常频繁地与你沟通，通过语言沟通和短信描述他虚构的事业成就，并不断表明你就是最好的异性选择，并强烈要求你亲自前往他所在的地区，亲自验证他的事业成就；并表明，如果见面不合适，还能介绍或安排你在他的公司里承担一个很高薪酬的职位，但这些都是传销组织的惯用伎俩，当受骗者被传销控制之后，基本被骗去财物，甚至控制人身自由，发展成为传销的下线。
                </p>
              </div>
            </div>
          </div>
          <!-- 09 -->
          <div class="contentL">
            <div class="bottom">
              <div class="right-content">
                <p class="title">09 <span>诱导酒驾诈骗</span></p>
                <p>
                  这种骗术发生在双方见面约会阶段，行骗者专门寻找有车一族为目标，盛情邀约见面吃饭。可能会带朋友一同出席，席间一起不停劝诱喝酒。饭后行骗者会以时间很晚了，距离很近，绝对没有交警查车等理由怂恿车主同意酒驾送其回家。车主只要同意，行骗者就会通知同伙驾车尾随并伺机撞车，撞车后利用车主酒驾不敢报警的心理，敲诈高额的费用。
                </p>
              </div>
              <div class="img-box right-img">
                <!-- <img
                  src="//i.zhenai.com/pc/portal/safe/images/layer_safe_drink.409a8d0.jpg"
                  alt=""
                /> -->
                <img src="../../assets/images/safe/9.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="guid-box" v-show="index == 3">
          <div class="contentL">
            <p class="title">01 <span>如何报案</span></p>
            <div class="bottom">
              <div class="right-content">
                <p class="tip">紧急情况下：拨打"110"报案</p>
                <p>
                  如果您遇到正在发生的违法犯罪活动或在其他紧急情况下，请立即拨打"110"报案求助，报案时请注意：
                </p>
                <p>
                  1、首先要保持冷静，并迅速利用身边一切可以利用的通讯工具（如手机、小灵通、公用电话、磁卡电话、普通电话、投币电话等）拨打110电话（不加区号）。电信部门会自动将您的电话转至当地的公安机关（免收任何费用），投币、磁卡电话不用投币或插卡。
                </p>
                <p>
                  2、电话接通后，110台接警系统会自动给您播放提示音（如："这里是某某公安局110报案服务台"），如系统向您提示"正在接警，不要挂机，请稍候"，这说明所有110线路正在接受其他人的报案，这时请您不要挂机，等待接警员的电话。
                </p>
                <p>
                  3、当电话接通后，请您按照接警员的提示，简明扼要地提供您需要报案的事情，发生的时间、地点（最好以附近明显标志物为参照）、现在的状况（受害人受伤及损伤情况）、嫌疑人情况、作案方式及逃跑方向等，并向接警员提供您的真实姓名、联系电话或住址等情况。
                </p>
                <p>
                  4、报案内容要实事求是，如实反映情况，以使接警人员作出准确的判断，采取相应的措施。
                </p>
                <p>
                  5、报案结束后，迅速离开现场或在原地等候民警的到来（视情况而定，报案时请就此询问接警人员），并保持通讯联络畅通。
                </p>
              </div>
              <div class="img-box right-img">
                <!-- <img
                  src="//i.zhenai.com/pc/portal/safe/images/layer_safe_call.f94616d.png"
                  alt=""
                /> -->
                <img src="../../assets/images/safe/110.png" alt="" />
              </div>
            </div>
          </div>
          <div class="contentL">
            <div class="bottom">
              <div class="img-box left-img">
                <!-- <img
                  src="//i.zhenai.com/pc/portal/safe/images/layer_safe_police.697856e.png"
                  alt=""
                /> -->
                <img src="../../assets/images/safe/pcs.png" alt="" />
              </div>
              <div class="left-content">
                <p class="tip">非紧急情况下：去派出所或公安局报案</p>
                <p>非紧急情况下，请及时前往派出所或公安局报案，报案时请注意</p>
                <p>1、请携带有效的身份证明文件。</p>
                <p>
                  2、尽可能提供嫌疑人的基本情况，如姓名、电话、电子邮箱地址、住址等。
                </p>
                <p>
                  3、尽可能提交与犯罪事实相关的证据材料，如您在也在网与嫌疑人沟通的站内邮件信息，以及经由站外通信工具的聊天记录，如QQ、MSN、微信、私人邮件等。另外，双方发送的手机短信，各种纸质票据（银行汇款单、邮政汇款单、消费发票、收据、回执等），双方的书面协议，以及录音等，均可作为证据提交给公安机关。提交详细、充分的证据，将有助于公安机关立案调查（如果公安机关要求也在网协助，我们会尽力协助进行相关后台证据的搜集）。
                </p>
                <p>
                  4、如果您就此案件或相关案件已经向法院或其他行政机关提起诉讼或者控告的，请向公安机关提交上述机关的受理或处理意见。
                </p>
              </div>
            </div>
          </div>
          <div class="contentL">
            <p class="title">
              02 <span>接到报案，公安机关可能如何处理？</span>
            </p>
            <div class="bottom">
              <div class="right-content">
                <p class="tip">接受报案</p>
                <p>1、接受报案的公安机关会制作询问笔录，由您签名确认。</p>
                <p>
                  2、公安机关会填写《接受案件回执单》或其他类似文书（下称"报案回执"），其中一份交给您收执。回执中通常会写明受案单位名称、受案民警姓名以及相关电话号码，您凭报案回执，可以通过电话或直接到接受案件的公安机关查询该案是否立案或者是否已提请检察院批准逮捕的情况。
                </p>
                <p>
                  3、如果公安机关未提供报案回执，您可以主动索取，并索要接待民警的姓名，电话等联系方式，以便今后了解立案情况及工作进展情况。
                </p>
              </div>
              <div class="img-box right-img">
                <!-- <img
                  src="//i.zhenai.com/pc/portal/safe/images/layer_safe_set.4e82859.png"
                  alt=""
                /> -->
                <img src="../../assets/images/safe/jcd.png" alt="" />
              </div>
            </div>
          </div>
          <div class="contentL">
            <div class="bottom">
              <div class="img-box left-img">
                <!-- <img
                  src="//i.zhenai.com/pc/portal/safe/images/layer_safe_condition.ae95530.png"
                  alt=""
                /> -->
                <img src="../../assets/images/safe/la.png" alt="" />
              </div>
              <div class="left-content">
                <p class="tip">立案条件</p>
                <p>
                  如果嫌疑人实施诈骗行为，可能构成诈骗罪，您可以向公安机关报案以寻求帮助，但公安机关是否立案侦查，则取决于该案件是否满足其立案条件，具体如诈骗财物的价值是否达到一定金额以上，或是否导致了严重的后果等（各地的追诉标准可能有所不同，具体请您在报案时询问接受报案的公安机关）。
                </p>
                <p>立案</p>
                <p>
                  公安机关接受案件后，经审查，认为有犯罪事实需要追究刑事责任，且属于自己管辖的，经县级以上公安机关负责人批准，予以立案。
                </p>
                <p>不予立案</p>
                <p>
                  根据法律规定，公安机关决定不予立案的，公安机关应当制作《不予立案通知书》，并在三日以内送达给您。您如果对不予立案决定不服的，可以在收到不予立案通知书后七日以内向作出决定的公安机关申请复议；公安机关应当在收到复议申请后七日以内作出决定，并书面通知您。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/layout/footer";
import Header from "@/components/layout/header";
import Nlh from "@/components/layout/nlh";
import local from "@/api/common/local.js";
export default {
  metaInfo: {
    title: "也在网-婚恋安全中心保障专家指南",
    // meta: [
    //   { charset: "utf-8" },
    //   {
    //     name: "viewport",
    //     content:
    //       "width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no",
    //   },
    //   {
    //     name: "keywords",
    //     content: "交友,交友网,征婚交友,网上交友,征婚,征婚网,征婚交友网,交友中心,婚恋交友,同城交友,离异征婚,同城约会吧,约会吧征婚,约会吧交友,北京交友,佛山交友,找对象,婚介,二婚,再婚,也在网,也在"
    //   }, {
    //     name: "description",
    //     content: "也在网创始于2016年，也在网红娘均经过专业的婚恋学，心理学培训，结合多年的征婚服务经验，给您提供用心细致，专业个性化的红娘服务。也在网：成就天下姻缘！"
    //   }
    // ],
  },
  data() {
    return {
      index: 1,
      iSheader: true,
    };
  },
  components: {
    Footer,
    Header,
    Nlh,
  },
  created() {
    let Data = local.get("access_token");
    if (Data) {
      this.iSheader = true;
    } else {
      this.iSheader = false;
    }
  },
};
</script>

<style lang="less" scoped>
body,
template {
  width: 100%;
}

.content {
  padding-top: 110px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f5;

  .content-Img {
    display: flex;
    justify-content: center;
  }

  .content-o {
    display: flex;
    justify-content: center;

    .content-title {
      //   display: flex;
      //   justify-content: center;
      width: 1200px;
      box-sizing: border-box;
      //   width: 100%;
      height: 60px;
      position: relative;
      padding-left: 57px;
      margin-top: 30px;
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      line-height: 60px;
      letter-spacing: 1px;
      background: #fff;
      border: 1px solid #f4f4f4;
      border-radius: 6px;
    }

    .content-title:before {
      position: absolute;
      content: "";
      left: 42px;
      top: 22px;
      width: 6px;
      height: 16px;
      background: #fd686e;
    }
  }

  .tab {
    margin-top: 10px;
    margin-bottom: 40px;
    // display: flex;
    // justify-content: center;
    background-color: #fff;

    width: 1200px;

    .tab-header {
      width: 1200px;
      padding-left: 44px;
      border: 1px solid #f4f4f4;
      border-radius: 6px 6px 0 0;

      .active-tab {
        // font-weight: 500;
        // color: #8b76f9;
        // border-bottom: 2px solid #8b76f9;

        font-weight: bold;
        color: #fd686e !important;
        border-bottom: 2px solid #fd686e;
      }

      .default-tab {
        cursor: pointer;
        padding: 14px 13px 10px;
        margin-right: 60px;
        font-size: 14px;
        font-family: PingFangSC-Regular;
        font-weight: 400;
        color: #666666;
        line-height: 45px;
        letter-spacing: 1px;
      }
    }

    .report-box {
      border: 1px solid #f4f4f4;
      border-top: none;
      border-radius: 0 0 6px 6px;
      padding: 47px 58px 58px 59px;

      .report-desc {
        width: 1083px;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 26px;
      }

      .report-flex {
        display: flex;
        margin-top: 25px;

        .content-left {
          margin-top: 151px;
          width: 441px;
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          line-height: 23px;
        }
      }
    }

    .guid-box {
      padding: 55px 58px 92px 58px;
      border: 1px solid #f4f4f4;
      border-top: none;
      border-radius: 0 0 6px 6px;

      .guid-desc {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 26px;
        margin-bottom: 30px;
      }

      .contentL {
        width: 100%;
        height: 100%;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 26px;
        margin-bottom: 54px;
        text-align: justify;

        p {
          .strong {
            color: #ff7e01;
          }
        }

        img {
          width: 385px;
          height: 385px;
        }

        .title {
          // display: flex;
          font-size: 80px;
          font-weight: 900;
          color: #ff767b;
          line-height: 100px;

          span {
            display: inline-block;
            // margin-left: 26px;
            font-size: 23px;
            font-weight: 400;
            color: #333333;
            line-height: 22px;
          }
        }

        .bottom {
          display: flex;

          //   justify-content: space-around;
          .right-content {
            width: 645px;
            height: 100%;

            .tip {
              margin-top: 26px;
              margin-bottom: 20px;
              font-size: 23px;
              font-weight: 500;
              color: #333333;
              line-height: 32px;
              letter-spacing: 2px;
            }

            .tips {
              color: #fd686e;
              margin-top: 13px;
            }
          }

          .right-img {
            margin-left: 38px;

            img {
              vertical-align: bottom;
              max-width: 100%;
              width: 385px;
              height: 385px;
            }
          }

          .left-img {
            margin-right: 106px;
          }

          .img-box {
            width: 385px;
            height: 100%;
          }

          .left-content {
            height: 100%;

            .tip {
              margin-top: 26px;
              margin-bottom: 20px;
              font-size: 23px;
              font-weight: 500;
              color: #333333;
              line-height: 32px;
              letter-spacing: 2px;
            }

            .tips {
              color: #fd686e;
              margin-top: 13px;
            }
          }
        }
      }
    }
  }
}</style>